import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";
import EditBankLayer from "../../components/banks/EditBankLayer";


const EditBankPage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Edit Bank" />

        {/* AddUserLayer */}
        <EditBankLayer />


      </MasterLayout>
    </>
  );
};

export default EditBankPage;
