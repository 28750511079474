import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { API_URL } from "../../constants/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AddAreaLayer = () => {
 
  const token = localStorage.getItem("pfp-token");
  const navigate = useNavigate();
  const [area_name, setName] = useState("")
 
  const location_country_id = 1;
  const location_state_id = 1;
  const location_city_id = 1;




  const handleSubmit = async e => {
    e.preventDefault()

    const data = {
     
      location_country_id,
      location_state_id,
      location_city_id,
      area_name
    }
    
      try {
        const loading = toast.loading("Adding new area")
        const response = await fetch(
          `${API_URL}/api/backend/locations/areas/add`,
          {
            method: "POST",
            body: JSON.stringify(data),  
            headers: {
              "Content-Type": "Application/json",
              "Authorization": `Bearer ${token}`,
            }
          },
        )

        const json = await response.json()

        if (response.ok) {
          toast.dismiss(loading)
          toast.success("Area added successfully")
          navigate("/all-areas")
  
        }else{
          toast.dismiss(loading)
          toast.error("Error adding area")
        }
      } catch (error) {
      } finally {
      }

  }
 
  return (
    <div className="card h-100 p-0 radius-12 overflow-hidden">
      <div className="card-body p-40">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-6">
              <div className="mb-20">
                <label
                  htmlFor="name"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Name <span className="text-danger-600">*</span>
                </label>
                <input
                  type="text"
                  className="form-control radius-8"
                  id="name"
                  placeholder="Enter Area Name"
                  onChange={(e)=>setName(e.target.value)}
                  required
                />
              </div>
            </div>
          
            <div className="d-flex align-items-center justify-content-start gap-3 mt-24">
              <button
                type="reset"
                className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-40 py-11 radius-8"
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-primary-600 border border-primary-600 text-md px-24 py-12 radius-8"
              >
                Save Change
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddAreaLayer;
