import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";


import EditDishCategoryLayer from "../../components/dishCategory/EditDishCategoryLayer";


const EditDishCategoryPage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Edit Dish Category" />

        {/* AddUserLayer */}
        <EditDishCategoryLayer />


      </MasterLayout>
    </>
  );
};

export default EditDishCategoryPage;
