import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";
import AddDishCategoryLayer from "../../components/dishCategory/AddDishCategoryLayer";



const AddDishCategoryPage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Add Dish Category" />

        {/* AddUserLayer */}
        <AddDishCategoryLayer />


      </MasterLayout>
    </>
  );
};

export default AddDishCategoryPage;
