import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useContext, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { API_URL } from "../../constants/api";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ModeContext } from "../../App";

const AddMenuLayer = () => {
  const { id } = useParams();
  const token = localStorage.getItem("pfp-token");
  const navigate = useNavigate();

  const [dish_name, setDishName] = useState("");
  const [dish_description, setDishDescription] = useState("");
  const [serving_size, setServingSize] = useState(0);
  const [price, setPrice] = useState(0);

  const [restaurant_dish_category_id, setDishCategoryId] = useState();

  const [dishCategories, setDishCategories] = useState([]);

  const [restaurant_dish_cuisine_id, setDishCuisineId] = useState();
  const [dishCuisine, setDishCuisines] = useState([]);
  const [status, setActive] = useState(true);
  const [sort_order, setSort] = useState(0);
  const [validationErrors, setValidationErrors] = useState({});
  const { mode, setMode } = useContext(ModeContext);
  useEffect(() => {
    const fetchAllDishCategories = async () => {
      const searchParams = new URLSearchParams({
        offset: 1,
        limit: 100000,
      });
      try {
        const response = await fetch(
          `${API_URL}/api/backend/restaurants/dishes/category/list?${searchParams}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const json = await response.json();

        if (response.ok) {
          const options = json.data.map((cat) => ({
            label: cat.category_name,
            value: cat.id,
          }));
          setDishCategories(options || []);
        }
      } catch (error) {}
    };
    const fetchAllDishCuisines = async () => {
      const searchParams = new URLSearchParams({
        offset: 1,
        limit: 100000,
      });
      try {
        const response = await fetch(
          `${API_URL}/api/backend/restaurant/dish/cusines/list?${searchParams}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const json = await response.json();

        if (response.ok) {
          const options = json.data.map((cui) => ({
            label: cui.cuisine_name,
            value: cui.id,
          }));
          setDishCuisines(options || []);
        }
      } catch (error) {}
    };

    fetchAllDishCategories();
    fetchAllDishCuisines();
  }, [id]);

  // Validation for Select Fields

  const validateForm = () => {
    let errors = {};
    if (!restaurant_dish_category_id) {
      toast.error("Dish category is required");
    }
    if (!restaurant_dish_cuisine_id) {
      toast.error("Cuisine is required");
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0; // Returns true if there are no errors
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    await validateForm();

    let active = status === true ? 1 : 0;

    const restaurant_id = id;

    const formData = new FormData();

    formData.append("restaurant_id", restaurant_id);
    formData.append("restaurant_dish_category_id", restaurant_dish_category_id);
    formData.append("restaurant_dish_cuisine_id", restaurant_dish_cuisine_id);
    formData.append("dish_name", dish_name);
    formData.append("dish_description", dish_description);
    formData.append("serving_size", serving_size);
    formData.append("price", price);
    formData.append("sort_order", sort_order);
    formData.append("active", active);

    try {
      const loading = toast.loading("Adding new menu");
      const response = await fetch(
        `${API_URL}/api/backend/restaurants/menu/add`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        toast.dismiss(loading);
        toast.success("Menu added successfully!");
        navigate(`/all-menus/${id}`);
      } else {
        toast.dismiss(loading);
        toast.success("Error adding menu!");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const colorStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "#273142",
      borderColor: state.isFocused ? "#blue" : "#ccc",
      boxShadow: state.isFocused ? "0 0 0 1px #blue" : "none",
      "&:hover": {
        borderColor: "#blue",
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? "#24A97B"
        : state.isFocused
        ? "#273142"
        : "#273142",
      color: state.isSelected ? "black" : "white",
      "&:hover": {
        backgroundColor: "#000000",
      },
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "#ffffff",
      border: "1px solid #ccc",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#ffffff",
    }),
    singleValue: (base) => ({
      ...base,
      color: "#ffffff",
    }),
    input: (base) => ({
      ...base,
      color: "#ffffff", // Set the search text color here
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: "#000000", // Background color for selected values
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: "#ffffff", // Text color for selected value labels
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: "#ffffff", // Text color for the remove icon
      "&:hover": {
        backgroundColor: "red", // Background color on hover
        color: "white", // Text color on hover
      },
    }),
  };
  return (
    <div className="card h-100 p-0 radius-12 overflow-hidden">
      <div className="card-body p-40">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-4">
              <div className="mb-20">
                <label
                  htmlFor="depart"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Choose Dish Category
                  <span className="text-danger-600">*</span>{" "}
                </label>
                <Select
                  onChange={(e) => setDishCategoryId(e.value)}
                  options={dishCategories}
                  styles={mode !== "light" ? colorStyles : undefined}
                  classNamePrefix="select2-selection"
                  validate={{ required: { value: true } }}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mb-20">
                <label
                  htmlFor="depart"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Choose Dish Cuisine
                  <span className="text-danger-600">*</span>{" "}
                </label>
                <Select
                  onChange={(e) => setDishCuisineId(e.value)}
                  options={dishCuisine}
                  styles={mode !== "light" ? colorStyles : undefined}
                  classNamePrefix="select2-selection"
                  validate={{ required: { value: true } }}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mb-20">
                <label
                  htmlFor="name"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Dish Name <span className="text-danger-600">*</span>
                </label>
                <input
                  className="form-control radius-8"
                  id="name"
                  placeholder="Enter Dish Name"
                  onChange={(e) => setDishName(e.target.value)}
                  type="text"
                  required
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="mb-20">
                <label
                  htmlFor="description"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Dish Description
                </label>
                <textarea
                  type="text"
                  className="form-control radius-8"
                  id="description"
                  placeholder="Enter Dish Description"
                  onChange={(e) => setDishDescription(e.target.value)}
                  rows={5}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mb-20">
                <label
                  htmlFor="name"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Serving Size
                </label>
                <input
                  className="form-control radius-8"
                  id="name"
                  placeholder="Serving Size"
                  onChange={(e) => setServingSize(e.target.value)}
                  type="number"
                  min={0}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mb-20">
                <label
                  htmlFor="name"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Price
                </label>
                <input
                  className="form-control radius-8"
                  id="name"
                  placeholder="Enter Price"
                  onChange={(e) => setPrice(e.target.value)}
                  type="number"
                  min={0}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mb-20">
                <label
                  htmlFor="name"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Sort Order
                </label>
                <input
                  className="form-control radius-8"
                  id="name"
                  placeholder="Enter Sort Number"
                  onChange={(e) => setSort(e.target.value)}
                  type="number"
                  min={0}
                />
              </div>
            </div>
            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  onChange={(e) => setActive(e.target.checked)}
                  defaultChecked={status}
                  id="one"
                />
              </div>
              <label
                htmlFor="one"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
                Active
              </label>
            </div>
            <div className="d-flex align-items-center justify-content-center gap-3 mt-24">
              <button
                type="submit"
                className="btn btn-primary-600 border border-primary-600 text-md px-24 py-12 radius-8"
              >
                Add Menu
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddMenuLayer;
