import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../constants/api";
import { format } from "date-fns";
import { FeaturedLocationPageContext } from "../../App";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const AllFeaturedRestaurantsLayer = () => {

  const [records, setRecords] = useState([]);
  const [data, setData] = useState(null);
  const token = localStorage.getItem("pfp-token");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [update, setUpdate] = useState(0);
  const { featuredLocationPage, setFeaturedLocationPage } = useContext(FeaturedLocationPageContext);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchAllLocations = async () => {
      if (search.trim() !== "") {
        await setLimit(10);
        await setFeaturedLocationPage(1);
      }

      try {
        setLoading(true);

        const searchParams = new URLSearchParams({
          offset: featuredLocationPage,
          limit: limit,
          search_term: search,
        });
        const response = await fetch(
          `${API_URL}/api/backend/restaurant/locations/list?${searchParams}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const json = await response.json();

        if (response.ok) {
          setRecords(json.data);
          setData(json.data);
          setLastPage(json.pagination.last_page);
          setTotal(json.pagination.total);
        } else {
          setRecords([]);
        }
      } catch (error) {
        alert(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllLocations();
  }, [update, limit, featuredLocationPage, search]);

  const handleDelete = (myId) => {
    console.log(myId);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ms-2",
        cancelButton: "btn btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const deleteUser = async (d_id) => {
            const response = await fetch(
              `${API_URL}/api/backend/restaurant/locations/delete/${d_id}`,
              {
                method: "DELETE",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            setUpdate(update + 1);
          };
          deleteUser(myId);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Location has been deleted.",
            icon: "success",
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Location is safe :)",
            icon: "error",
          });
        }
      });
  };

  const handleEdit = (myId, restId) => {
    navigate("/edit-location/" + myId + "/" + restId + "/featured");
  };
  return (
    <div className="card h-100 p-0 radius-12">
      <div className="card-header border-bottom bg-base py-16 px-24 d-flex align-items-center flex-wrap gap-3 justify-content-between">
        <div className="d-flex flex-wrap align-items-center gap-3">
          <div className="d-flex align-items-center gap-2">
            <span>Show</span>
            <select
              className="form-select form-select-sm w-auto"
              defaultValue={limit}
              onChange={(e) => setLimit(parseInt(e.target.value))}
            >
              <option value={limit} disabled>
                {limit}
              </option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div className="icon-field">
            <input
              type="text"
              name="#0"
              className="form-control form-control-sm w-auto"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
            <span className="icon">
              <Icon icon="ion:search-outline" />
            </span>
          </div>
        </div>
    
      </div>

      <div className="card-body p-24">
        <div className="table-responsive scroll-sm">
          <table className="table bordered-table sm-table mb-0">
            <thead>
              <tr>
                <th scope="col">
                  <div className="d-flex align-items-center gap-10">
                    <div className="form-check style-check d-flex align-items-center"></div>
                    ID
                  </div>
                </th>

                <th scope="col">Restaurant Name</th>
                <th scope="col">Address</th>

                <th scope="col" className="dt-orderable-asc dt-orderable-desc">
                  Date Created
                </th>
                <th scope="col" className="text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {records &&
                records.map((user) => (
                  <tr key={user.id}>
                    <td>
                      <div className="d-flex align-items-center gap-10">
                        {user.id}
                      </div>
                    </td>

                    <td>
                      <span className="text-md mb-0 fw-normal text-secondary-light">
                        {user.restaurant_name}
                      </span>
                    </td>
                    <td>
                      <span className="text-md mb-0 fw-normal text-secondary-light">
                        {user.location_address}
                      </span>
                    </td>

                    <td>{format(user.created_at, "dd MMM, yyyy")}</td>
                    <td className="text-center">
                      <div className="d-flex align-items-center gap-10 justify-content-center">
                        <button
                          onClick={() => handleEdit(user.id, user.restaurant_id)}
                          className="bg-info-focus bg-hover-info-200 text-info-600 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle"
                        >
                          <Icon icon="lucide:edit" className="menu-icon" />
                        </button>
                        <button
                          type="button"
                          onClick={() => handleDelete(user.id)}
                          className="remove-item-btn bg-danger-focus bg-hover-danger-200 text-danger-600 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle"
                        >
                          <Icon
                            icon="fluent:delete-24-regular"
                            className="menu-icon"
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 mt-24">
          <span>
            Showing {limit} of {total} entries
          </span>
          <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center">
            {featuredLocationPage > 1 ? (
              <li className="page-item">
                <button
                  className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px  text-md"
                  onClick={
                    featuredLocationPage > 1
                      ? () => setFeaturedLocationPage(featuredLocationPage - 1)
                      : () => setFeaturedLocationPage(featuredLocationPage)
                  }
                >
                  <Icon icon="ep:d-arrow-left" className="" />
                </button>
              </li>
            ) : (
              <></>
            )}

            {featuredLocationPage > 2 ? (
              <li className="page-item">
                <button
                  className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md"
                  onClick={() => setFeaturedLocationPage(featuredLocationPage - 2)}
                >
                  {featuredLocationPage - 2}
                </button>
              </li>
            ) : (
              <></>
            )}
            {featuredLocationPage > 1 ? (
              <li className="page-item">
                <button
                  className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md"
                  onClick={() => setFeaturedLocationPage(featuredLocationPage - 1)}
                >
                  {featuredLocationPage - 1}
                </button>
              </li>
            ) : (
              <></>
            )}

            <li className="page-item">
              <Link
                className="page-link text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md bg-primary-600 text-white"
                to="#"
              >
                {featuredLocationPage}
              </Link>
            </li>

            {featuredLocationPage < lastPage ? (
              <li className="page-item">
                <button
                  className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md"
                  onClick={() => setFeaturedLocationPage(featuredLocationPage + 1)}
                >
                  {featuredLocationPage + 1}
                </button>
              </li>
            ) : (
              <></>
            )}
            {featuredLocationPage + 1 < lastPage ? (
              <li className="page-item">
                <button
                  className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md"
                  onClick={() => setFeaturedLocationPage(featuredLocationPage + 2)}
                >
                  {featuredLocationPage + 2}
                </button>
              </li>
            ) : (
              <></>
            )}
            {featuredLocationPage !== lastPage ? (
              <li className="page-item">
                <button
                  className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px  text-md"
                  onClick={() => setFeaturedLocationPage(featuredLocationPage + 1)}
                >
                  {" "}
                  <Icon icon="ep:d-arrow-right" className="" />{" "}
                </button>
              </li>
            ) : (
              <></>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AllFeaturedRestaurantsLayer;
