import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { API_URL } from "../../constants/api";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const AddBannerLayer = () => {

  const token = localStorage.getItem("pfp-token");
  const navigate = useNavigate();
  const [url, setURL] = useState("")
  const [image, setImage] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});


  // bank image handling

  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    if (e.target.files.length) {
      const src = URL.createObjectURL(e.target.files[0]);
      setImagePreview(src);
      setImage(e.target.files[0]);
    }
  };

  const removeImage = () => {
    setImagePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      setImage(null);
    }
  };

  useEffect(() => {
    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);

  // Validation for Select Fields
  const validateForm = () => {
    let errors = {};
    if (!image) {
     toast.error("Banner image required")
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0; // Returns true if there are no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await validateForm();



    const formData = new FormData();

    formData.append("url", url)
  
    formData.append("banner_image", image)

    try {
      const loading = toast.loading("Adding new card....")
      const response = await fetch(`${API_URL}/api/backend/banner/add`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const json = await response.json();

      if (response.ok) {
        toast.dismiss(loading)
        toast.success("Banner added successfully")
        navigate(`/all-banners`);
        // setAlertVisible(true)
        // setTimeout(() => setAlertVisible(false), 5000)
      }else{
        toast.dismiss(loading)
        toast.error("Error adding banner")
      }
    } catch (error) {
    } finally {
    }
  };
  return (
    <div className="card h-100 p-0 radius-12 overflow-hidden">
      <div className="card-body p-40">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-6">
              <div className="mb-20">
                <label
                  htmlFor="name"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  URL <span className="text-danger-600">*</span>
                </label>
                <input
                  type="text"
                  className="form-control radius-8"
                  id="name"
                  placeholder="Banner url"
                  onChange={(e) => setURL(e.target.value)}
                  required
                />
              </div>
            </div>
           

            <div className="col-sm-12">
              <div className="mb-20">
                <label
                  htmlFor="email"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Banner Image
                </label>
                <div className="upload-image-wrapper d-flex align-items-center gap-3">
                  {/* Image preview section */}
                  {imagePreview ? (
                    <div className="uploaded-img position-relative h-120-px w-120-px border input-form-light radius-8 overflow-hidden border-dashed bg-neutral-50">
                      <button
                        type="button"
                        onClick={removeImage}
                        className="uploaded-img__remove position-absolute top-0 end-0 z-1 text-2xxl line-height-1 me-8 mt-8 d-flex"
                        aria-label="Remove uploaded image"
                      >
                        <Icon
                          icon="radix-icons:cross-2"
                          className="text-xl text-danger-600"
                        ></Icon>
                      </button>
                      <img
                        id="uploaded-img__preview"
                        className="w-100 h-100 object-fit-cover"
                        src={imagePreview}
                        alt="Preview"
                      />
                    </div>
                  ) : (
                    <label
                      className="upload-file h-120-px w-120-px border input-form-light radius-8 overflow-hidden border-dashed bg-neutral-50 bg-hover-neutral-200 d-flex align-items-center flex-column justify-content-center gap-1"
                      htmlFor="upload-file"
                    >
                      <Icon
                        icon="solar:camera-outline"
                        className="text-xl text-secondary-light"
                        onClick={(e) => {
                          e.preventDefault();
                          fileInputRef.current.click();
                        }}
                      ></Icon>
                      <span className="fw-semibold text-secondary-light">
                        Upload
                      </span>
                    </label>
                  )}

                  {/* Always render the input, but hide it */}
                  <input
                    id="upload-file"
                    type="file"
                    onChange={handleFileChange}
                    hidden
                    ref={fileInputRef}
                    accept="image/*" // Optional: restrict to image files
                  />
                </div>
              </div>
            </div>

      

            <div className="d-flex align-items-center justify-content-center gap-3 mt-24">
              <button
                type="reset"
                className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-40 py-11 radius-8"
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-primary-600 border border-primary-600 text-md px-24 py-12 radius-8"
              >
                Save Change
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddBannerLayer;
