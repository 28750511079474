import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";

import Breadcrumb from "../../components/Breadcrumb";

import AllRestaurantCategoriesLayer from "../../components/restaurantCategory/AllRestaurantCategoriesLayer";


const AllRestaurantCategoryPage = () => {
  return (
    <>

      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="All Restaurant Categories" />

        {/* TableDataLayer */}
        <AllRestaurantCategoriesLayer />
        {/* <AlertLayer/> */}
    

      </MasterLayout>

    </>
  );
};

export default AllRestaurantCategoryPage; 
