import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";
import AddBannerLayer from "../../components/media/AddBannerLayer";


const AddBannerPage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Add Banner" />

        {/* AddUserLayer */}
        <AddBannerLayer />


      </MasterLayout>
    </>
  );
};

export default AddBannerPage;
