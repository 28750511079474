import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useContext, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { API_URL } from "../../constants/api";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ModeContext } from "../../App";

const AddDiscountLayer = () => {
  const { id } = useParams();
  const token = localStorage.getItem("pfp-token");
  const navigate = useNavigate();
  const [bank_id, setBankId] = useState();

  const [banks, setBanks] = useState([]);

  const [terms_and_conditions, setTerms] = useState("");

  const [bank_card_id, setCardId] = useState();
  const [cards, setCards] = useState([]);

  const [discount_percentage, setDiscount] = useState(0);
  const [validationErrors, setValidationErrors] = useState({});
  const { mode, setMode } = useContext(ModeContext);
  useEffect(() => {
    const fetchAllBanks = async () => {
      const searchParams = new URLSearchParams({
        offset: 1,
        limit: 100000,
      });
      try {
        const response = await fetch(`${API_URL}/api/backend/banks/list?${searchParams}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const json = await response.json();

        if (response.ok) {
          const options = json.data.map((bank) => ({
            label: bank.bank_name,
            value: bank.id,
          }));
          setBanks(options || []);
        }
      } catch (error) {}
    };

    fetchAllBanks();
  }, [id]);
  useEffect(() => {
   
    const fetchAllBankCards = async () => {
      const searchParams = new URLSearchParams({
        offset: 1,
        limit: 100000,
      });
      console.log("function");
      try {
        const response = await fetch(
          `${API_URL}/api/backend/bank/cards/list/${bank_id}?${searchParams}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const json = await response.json();

        if (response.ok) {
          const options = json.data.map((bank) => ({
            label: `${bank.card_type.toUpperCase()} - ${bank.card_name}`,
            value: bank.id,
          }));
          setCards(options || []);
        }
      } catch (error) {}
    };

    if (bank_id) {
      fetchAllBankCards();
    }
  }, [bank_id]);

  // Validation for Select Fields

  const validateForm = () => {
    let errors = {};
    if (!bank_id) {
      toast.error("Bank is required");
    }
    if (!bank_card_id) {
      toast.error("Card is required");
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0; // Returns true if there are no errors
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    await validateForm();

    const restaurant_id = id;

    const disc = {
      restaurant_id,
      bank_id,
      bank_card_id,
      terms_and_conditions,
      discount_percentage,
    };

    try {
      const loading = toast.loading("Adding new discount");
      const response = await fetch(
        `${API_URL}/api/backend/restaurant/bank/card/discounts/add`,
        {
          method: "POST",
          body: JSON.stringify(disc),
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        toast.dismiss(loading);
        toast.success("Discount added successfully");
        navigate(`/all-discounts/${id}`);
      } else {
        toast.dismiss(loading);
        toast.error("Error adding discount");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const colorStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "#273142",
      borderColor: state.isFocused ? "#blue" : "#ccc",
      boxShadow: state.isFocused ? "0 0 0 1px #blue" : "none",
      "&:hover": {
        borderColor: "#blue",
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? "#24A97B"
        : state.isFocused
        ? "#273142"
        : "#273142",
      color: state.isSelected ? "black" : "white",
      "&:hover": {
        backgroundColor: "#000000",
      },
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "#ffffff",
      border: "1px solid #ccc",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#ffffff",
    }),
    singleValue: (base) => ({
      ...base,
      color: "#ffffff",
    }),
    input: (base) => ({
      ...base,
      color: "#ffffff", // Set the search text color here
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: "#000000", // Background color for selected values
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: "#ffffff", // Text color for selected value labels
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: "#ffffff", // Text color for the remove icon
      "&:hover": {
        backgroundColor: "red", // Background color on hover
        color: "white", // Text color on hover
      },
    }),
  };
  return (
    <div className="card h-100 p-0 radius-12 overflow-hidden">
      <div className="card-body p-40">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-6">
              <div className="mb-20">
                <label
                  htmlFor="depart"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Choose Bank
                  <span className="text-danger-600">*</span>{" "}
                </label>
                <Select
                  onChange={(e) => setBankId(e.value)}
                  options={banks}
                  styles={mode !== "light" ? colorStyles : undefined}
                  classNamePrefix="select2-selection"
                  validate={{ required: { value: true } }}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-20">
                <label
                  htmlFor="depart"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Choose Card
                  <span className="text-danger-600">*</span>{" "}
                </label>
                <Select
                  onChange={(e) => setCardId(e.value)}
                  options={cards}
                  styles={mode !== "light" ? colorStyles : undefined}
                  classNamePrefix="select2-selection"
                  validate={{ required: { value: true } }}
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="mb-20">
                <label
                  htmlFor="description"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Terms And Conditions
                </label>
                <textarea
                  type="text"
                  className="form-control radius-8"
                  id="description"
                  placeholder="Enter Terms And Conditions"
                  onChange={(e) => setTerms(e.target.value)}
                  rows={5}
                />
              </div>
            </div>

            <div className="mb-20">
              <label
                htmlFor="name"
                className="form-label fw-semibold text-primary-light text-sm mb-8"
              >
                Discount % <span className="text-danger-600">*</span>
              </label>
              <input
                className="form-control radius-8"
                id="name"
                placeholder="Enter Discount percentage in number"
                onChange={(e) => setDiscount(e.target.value)}
                type="number"
                min={0}
                max={100}
                required
              />
            </div>

            <div className="d-flex align-items-center justify-content-center gap-3 mt-24">
              <button
                type="submit"
                className="btn btn-primary-600 border border-primary-600 text-md px-24 py-12 radius-8"
              >
                Add Discount
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddDiscountLayer;
