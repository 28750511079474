import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../constants/api";
import { format } from "date-fns";
import { PhasePageContext } from "../../App";
import Swal from "sweetalert2";
import "./AllPhases.css";

const AllPhasesLayer = () => {
  const { id } = useParams();
  const [records, setRecords] = useState([]);
  const [data, setData] = useState(null);
  const token = localStorage.getItem("pfp-token");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [update, setUpdate] = useState(0);
  const { phasePage, setPhasePage } = useContext(PhasePageContext);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [search, setSearch] = useState("");

  const location_country_id = 1;
  const location_state_id = 1;
  const location_city_id = 1;
  useEffect(() => {
    const fetchAllPhases = async () => {
      if (search.trim() !== "") {
        await setLimit(10);
        await setPhasePage(1);
      }

      try {
        setLoading(true);

        const searchParams = new URLSearchParams({
          offset: phasePage,
          limit: limit,
          search_term: search,
          location_country_id: location_country_id, 
          location_state_id: location_state_id, 
          location_city_id: location_city_id,
          location_area_id: id,
          
        });
        const response = await fetch(
          `${API_URL}/api/backend/locations/phases/list?${id}?${searchParams}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const json = await response.json();

        if (response.ok) {
          const sortedData = json.data.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          setRecords(sortedData);
          setData(sortedData);
          setLastPage(json.pagination.last_page);
          setTotal(json.pagination.total);
        } else {
          setRecords([]);
        }
      } catch (error) {
        alert(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllPhases();
  }, [update, limit, phasePage, search, location_country_id]);

  const handleDelete = (myId) => {
    console.log(myId);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ms-2",
        cancelButton: "btn btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const deleteUser = async (d_id) => {
            const response = await fetch(
              `${API_URL}/api/backend/locations/phases/delete/${d_id}`,
              {
                method: "DELETE",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            setUpdate(update + 1);
          };
          deleteUser(myId);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Phase has been deleted.",
            icon: "success",
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Phase is safe :)",
            icon: "error",
          });
        }
      });
  };

  const handleEdit = (phaseId) => {
    navigate("/edit-phase/" + phaseId + "/" + id);
  };
  return (
    <div className="card h-100 p-0 radius-12">
      <div className="card-header border-bottom bg-base py-16 px-24 d-flex align-items-center flex-wrap gap-3 justify-content-between">
        <div className="d-flex flex-wrap align-items-center gap-3">
          <div className="d-flex align-items-center gap-2">
            <span>Show</span>
            <select
              className="form-select form-select-sm w-auto"
              defaultValue={limit}
              onChange={(e) => setLimit(parseInt(e.target.value))}
            >
              <option value={limit} disabled>
                {limit}
              </option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div className="icon-field">
            <input
              type="text"
              name="#0"
              className="form-control form-control-sm w-auto"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
            <span className="icon">
              <Icon icon="ion:search-outline" />
            </span>
          </div>
        </div>
        <div className="d-flex flex-wrap align-items-center gap-3">
          {/* <select
            className="form-select form-select-sm w-auto"
            defaultValue={0}
            onChange={(e) => setGroup(parseInt(e.target.value))}
          >
            <option value={0}>All Users</option>
            <option value={1}>Admin</option>
            <option value={2}>Data Entry</option>
            <option value={3}>Comment Reviewer</option>
          </select> */}
          <Link to={`/add-phase/${id}`} className="btn btn-sm btn-primary-600">
            <i className="ri-add-line" /> Add Phase
          </Link>
        </div>
      </div>

      <div className="card-body p-24">
        <div className="table-responsive scroll-sm">
          <table className="table bordered-table sm-table mb-0">
            <thead>
              <tr>
                <th scope="col">
                  <div className="d-flex align-items-center gap-10">
                    <div className="form-check style-check d-flex align-items-center"></div>
                    ID
                  </div>
                </th>

           
                <th scope="col">Phase Name</th>
                <th scope="col">Area Name</th>
             
                <th scope="col" className="text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {records &&
                records.map((user) => (
                  <tr key={user.id}>
                    <td>
                      <div className="d-flex align-items-center gap-10">
                        {user.id}
                      </div>
                    </td>

                  
                    <td>
                      <span className="text-md mb-0 fw-normal text-secondary-light">
                        {user.phase_name}
                      </span>
                    </td>
                    <td>
                      <span className="text-md mb-0 fw-normal text-secondary-light">
                        {user.area_name}
                      </span>
                    </td>
                  
                    <td className="text-center">
                      <div className="d-flex align-items-center gap-10 justify-content-center">
                     
                        <button
                          onClick={() => handleEdit(user.id)}
                          className="bg-info-focus bg-hover-info-200 text-info-600 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle"
                        >
                          <Icon icon="lucide:edit" className="menu-icon" />
                        </button>
                        <button
                          type="button"
                          onClick={() => handleDelete(user.id)}
                          className="remove-item-btn bg-danger-focus bg-hover-danger-200 text-danger-600 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle"
                        >
                          <Icon
                            icon="fluent:delete-24-regular"
                            className="menu-icon"
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 mt-24">
          <span>
            Showing {limit} of {total} entries
          </span>
          <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center">
            {phasePage > 1 ? (
              <li className="page-item">
                <button
                  className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px  text-md"
                  onClick={
                    phasePage > 1
                      ? () => setPhasePage(phasePage - 1)
                      : () => setPhasePage(phasePage)
                  }
                >
                  <Icon icon="ep:d-arrow-left" className="" />
                </button>
              </li>
            ) : (
              <></>
            )}

            {phasePage > 2 ? (
              <li className="page-item">
                <button
                  className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md"
                  onClick={() => setPhasePage(phasePage - 2)}
                >
                  {phasePage - 2}
                </button>
              </li>
            ) : (
              <></>
            )}
            {phasePage > 1 ? (
              <li className="page-item">
                <button
                  className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md"
                  onClick={() => setPhasePage(phasePage - 1)}
                >
                  {phasePage - 1}
                </button>
              </li>
            ) : (
              <></>
            )}

            <li className="page-item">
              <Link
                className="page-link text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md bg-primary-600 text-white"
                to="#"
              >
                {phasePage}
              </Link>
            </li>

            {phasePage < lastPage ? (
              <li className="page-item">
                <button
                  className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md"
                  onClick={() => setPhasePage(phasePage + 1)}
                >
                  {phasePage + 1}
                </button>
              </li>
            ) : (
              <></>
            )}
            {phasePage + 1 < lastPage ? (
              <li className="page-item">
                <button
                  className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md"
                  onClick={() => setPhasePage(phasePage + 2)}
                >
                  {phasePage + 2}
                </button>
              </li>
            ) : (
              <></>
            )}
            {phasePage !== lastPage ? (
              <li className="page-item">
                <button
                  className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px  text-md"
                  onClick={() => setPhasePage(phasePage + 1)}
                >
                  {" "}
                  <Icon icon="ep:d-arrow-right" className="" />{" "}
                </button>
              </li>
            ) : (
              <></>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AllPhasesLayer;
