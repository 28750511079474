import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";
import AllDiscountsLayer from "../../components/restaurants/AllDiscountsLayer";


const AllDiscountsPage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Discounts" />

        {/* AddUserLayer */}
        <AllDiscountsLayer />


      </MasterLayout>
    </>
  );
};

export default AllDiscountsPage;
