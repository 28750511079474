import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { API_URL } from "../../constants/api";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const EditDishCuisineLayer = () => {
  const { id } = useParams()
  const token = localStorage.getItem("pfp-token");
  const navigate = useNavigate();
  const [cuisine_name, setName] = useState("")
  const [cuisine_description, setDescription] = useState("")
  const [cuisine_slug, setSlug] = useState("");

  const [validationErrors, setValidationErrors] = useState({});
  const [status, setActive] = useState();
  const [error, setError] = useState("")
  // bank image handling

  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);


  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await fetch(
          `${API_URL}/api/backend/restaurant/dish/cusines/${id}`,{
            headers: {
              "Authorization": `Bearer ${token}`,
            }
          }
        )
        const json = await response.json()
        if (response.ok) {
          setName(json.data.cuisine_name || "")
          setDescription(json.data.cuisine_description || "")
          setSlug(json.data.cuisine_slug || "")
          setActive(json.data.active === 1)
        
        } else {
          console.error("Failed to fetch category data:", json.message)
        }
      } catch (error) {
        console.error("Error fetching category data:", error)
      }
    }
    fetchCategory()
  }, [id])


  const fetchSlug = async (name) => {
    try {
      const params = new URLSearchParams({
        slug_string: name
      })
      const response = await fetch(`${API_URL}/api/backend/slug/generate?${params}`, {
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      });
      const json = await response.json();
 
      if (response.ok) {
        setSlug(json.data.slug); 
        console.log(json.data.slug)
      } else {
        console.error("Failed to generate slug");
      }
    } catch (error) {
      console.error("Error fetching slug:", error);
    }
  };

  const handleBlur = () => {
    if (cuisine_name) {
      fetchSlug(cuisine_name);
    }
  };

 

  const handleSubmit = async (e) => {
    e.preventDefault();

  

    let active = 0;
    if (status === true) {
      active = 1;
    } else if (status === false) {
      active = 0;
    }

    const dish = {
      cuisine_name,
      cuisine_description,
      cuisine_slug,
      active
    }

    try {
      const loading = toast.loading("Updating cuisine....")
      const response = await fetch(`${API_URL}/api/backend/restaurant/dish/cusines/update/${id}`, {
        method: "PUT",
        body: JSON.stringify(dish),
        headers: {
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const json = await response.json();

      if (response.ok) {
        toast.dismiss(loading)
        toast.success("Cuisine updated successfully")
        navigate(`/all-dish-cuisine`);
        // setAlertVisible(true)
        // setTimeout(() => setAlertVisible(false), 5000)
      }else{
        toast.dismiss(loading)
        toast.error("Error updating cuisine")
        setError(json.message)
      }
    } catch (error) {
    } finally {
    }
  };
  return (
    <div className="card h-100 p-0 radius-12 overflow-hidden">
      <div className="card-body p-40">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-6">
              <div className="mb-20">
                <label
                  htmlFor="name"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Name <span className="text-danger-600">*</span>
                </label>
                <input
                  type="text"
                  className="form-control radius-8"
                  id="name"
                  placeholder="Enter cuisine name"
                  onChange={(e) => setName(e.target.value)}
                  required
                  onBlur={handleBlur}
                  value={cuisine_name}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-20">
                <label
                  htmlFor="name"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Slug <span className="text-danger-600">*</span>
                </label>
                <input
                  type="text"
                  className="form-control radius-8"
                  id="name"
                  placeholder="Enter cuisine slug"
                  onChange={(e) => setSlug(e.target.value)}
                  required
                  value={cuisine_slug}
                />
                   {error.trim() !== "" && (
                        <div className="invalid-feedback d-block">
                          {error}
                        </div>
                      )}
              </div>
            </div>
         
            <div className="col-sm-12">
              <div className="mb-20">
                <label
                  htmlFor="description"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Cuisine Description
                </label>
                <textarea
                  type="text"
                  className="form-control radius-8"
                  id="description"
                  placeholder="Describe the cuisine"
                  onChange={(e) => setDescription(e.target.value)}
                  rows={5}
                  value={cuisine_description}
                />
              </div>
            </div>

           
            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  onClick={
                                
                    (e) =>  {setActive(!status); console.log(!status)}

                    
                  }
                  checked={status}
                />
              </div>
              <label
                htmlFor="sandbox"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
                Active
              </label>
            </div>

            <div className="d-flex align-items-center justify-content-center gap-3 mt-24">
            
              <button
                type="submit"
                className="btn btn-primary-600 border border-primary-600 text-md px-24 py-12 radius-8"
              >
                Save Change
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditDishCuisineLayer;
