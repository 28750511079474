import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";

import Breadcrumb from "../../components/Breadcrumb";
import AllCardsLayer from "../../components/banks/AllCardsLayer";


const AllCardsPage = () => {
  return (
    <>

      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="All Cards" />

        {/* TableDataLayer */}
        <AllCardsLayer />
        {/* <AlertLayer/> */}
    

      </MasterLayout>

    </>
  );
};

export default AllCardsPage; 
