import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../constants/api";
import { format } from "date-fns";
import { PendingRestaurantPageContext } from "../../App";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Tooltip } from "bootstrap/dist/js/bootstrap.bundle.min.js";
import Slider from "react-slick";
import { RestaurantsTabContext } from "../../pages/restaurants/AllRestaurantsPage";
const AllPendingRestaurantsLayer = () => {
  const [records, setRecords] = useState([]);
  const [data, setData] = useState(null);
  const token = localStorage.getItem("pfp-token");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [activeModal, setActiveModal] = useState(null);
  const [imageModal, setImageModal] = useState(null);
  const [update, setUpdate] = useState(0);
  const [restaurantImages, setRestaurantImages] = useState([]);
  const { pendingRestaurantPage, setPendingRestaurantPage } = useContext(
    PendingRestaurantPageContext
  );
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [search, setSearch] = useState("");
  const [reasons, setReason] = useState("");
  const [explaination, setExplanation] = useState("")
  const {updateTab, setUpdateTab} = useContext(RestaurantsTabContext)

  // Trigger the modal by setting the active row's ID
  const handleModalOpen = (id) => {
    setActiveModal(id);
  };

  // Close the modal
  const handleModalClose = () => {
    setActiveModal(null);
  };

  // Trigger the modal by setting the active row's ID
  const handleImageModalOpen = (id, files) => {
    setImageModal(id);
    setRestaurantImages(files);
  };

  // Close the modal
  const handleImageModalClose = () => {
    setImageModal(null);
    setRestaurantImages([]);
  };
  useEffect(() => {
    
    const fetchAllRestaurants = async () => {
      if (search.trim() !== "") {
        await setLimit(10);
        await setPendingRestaurantPage(1);
      }

      try {
        setLoading(true);

        const searchParams = new URLSearchParams({
          offset: pendingRestaurantPage,
          limit: limit,
          search_term: search,
          is_approved: 0,
        });

        const response = await fetch(
          `${API_URL}/api/backend/restaurants/list?${searchParams}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const json = await response.json();

        if (response.ok) {
          console.log(pendingRestaurantPage + "new 2");
          setRecords(json.data);
          setData(json.data);
          setLastPage(json.pagination.last_page);
          setTotal(json.pagination.total);
        } else {
          setRecords([]);
        }
      } catch (error) {
        alert(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllRestaurants();
  }, [update, limit, pendingRestaurantPage, search, updateTab]);

    const handleApprove = async (active, id) => {
      const data = active === 2 ? { active, reasons, explaination } : { active }
  
      const response = await fetch(
        `${API_URL}/api/backend/restaurants/approval/${id}`,
        {
          method: "PATCH",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "Application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      const json = await response.json();
  
      if (response.ok) {
        toast.success(json.message);
        setUpdate(update + 1);
     
          handleModalClose();
     
      }
    };

  const handleDelete = (myId) => {
    console.log(myId);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ms-2",
        cancelButton: "btn btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const deleteUser = async (d_id) => {
            const response = await fetch(
              `${API_URL}/api/backend/restaurants/delete/${d_id}`,
              {
                method: "DELETE",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            setUpdate(update + 1);
          };
          deleteUser(myId);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Restaurant has been deleted.",
            icon: "success",
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Restaurant is safe :)",
            icon: "error",
          });
        }
      });
  };

  useEffect(() => {
    const tooltipButtons = document.querySelectorAll(".my-tooltip-button");

    const tooltipInstances = Array.from(tooltipButtons).map((tooltipButton) => {
      const tooltipContent = tooltipButton.nextElementSibling?.innerHTML || "";

      return new Tooltip(tooltipButton, {
        title: tooltipContent,
        trigger: "hover",
        html: true,
        customClass: tooltipButton.getAttribute("data-bs-custom-class") || "",
      });
    });

    // Cleanup tooltips when the component unmounts
    return () => {
      tooltipInstances.forEach((tooltip) => tooltip.dispose());
    };
  }, [records]); // Add dependency to ensure it re-runs when `records` change.

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <button
        onClick={onClick}
        type="button"
        className={`${className} slick-next slick-arrow text-primary-600`}
        style={{}}
      >
        <Icon icon="ic:outline-keyboard-arrow-right" className="menu-icon" />
      </button>
    );
  }
  function SamplePrevArrow(props) {
    const { className, onClick } = props;

    return (
      <button
        onClick={onClick}
        type="button"
        className={`${className} slick-prev slick-arrow text-primary-600`}
        style={{}}
      >
        <Icon icon="ic:outline-keyboard-arrow-left" className="menu-icon" />
      </button>
    );
  }
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div className="card h-100 p-0 radius-12">
      <div className="card-header border-bottom bg-base py-16 px-24 d-flex align-items-center flex-wrap gap-3 justify-content-between">
        <div className="d-flex flex-wrap align-items-center gap-3">
        {activeModal && (
            <div
              className="modal fade show"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              style={{ display: "block", backdropFilter: "blur(5px)" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <form
                  className="modal-content"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleApprove(2, activeModal);
                  }}
                >
                  <div className="card">
                    <div className="card-header d-flex">
                      <h5 className="card-title mb-0">Reason</h5>
                      <button
                        type="button"
                        className="btn-close ms-auto"
                        onClick={handleModalClose}
                      ></button>
                    </div>
                    <div className="card-body">
                      <div className="row gy-3">
                        <div className="col-12">
                          <input
                            type="text"
                            name="#0"
                            className="form-control"
                            placeholder="Reason of rejection"
                            onChange={(e) => setReason(e.target.value)}
                        
                            required
                          />
                        </div>
                        <div className="col-12">
                          <textarea
                            type="text"
                            name="#0"
                            className="form-control"
                            placeholder="Explain reason of rejection"
                            onChange={(e) => setExplanation(e.target.value)}
                            rows={5}
                            required
                          />
                        </div>

                        <div className="col-12 d-flex justify-content-end">
                          <button type="submit" className="btn btn-danger-600">
                            Confirm Rejection
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
          {imageModal && (
            <div
              className="modal fade show"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              style={{ display: "block", backdropFilter: "blur(5px)" }}
            >
              <div className="modal-dialog modal-dialog-centered  modal-lg">
                <div className="col-sm-12 modal-content">
                  <div className="card p-0 overflow-hidden position-relative radius-12 bg-primary-100">
                    <div className="card-header py-16 px-24 bg-base border border-end-0 border-start-0 border-top-0 d-flex justify-content-between">
                      <h6 className="text-lg mb-0">{restaurantImages.length > 0 ? "Images" : "No Image"}</h6>
                      <button
                        type="button"
                        className="btn-close ms-auto"
                        onClick={handleImageModalClose}
                      ></button>
                    </div>

                    {restaurantImages.length > 0 ? (
                      <Slider {...settings}>
                        {restaurantImages.map((image, index) => (
                          <div
                            key={index}
                            className="gradient-overlay bottom-0 start-0 h-100"
                          >
                            <img
                              src={image.cdn_image_small}
                              alt={`Restaurant Image ${index + 1}`}
                              className="object-fit-contain"
                              style={{ height: "455px", width: "768px"}}
                            />
                          </div>
                        ))}
                        {restaurantImages.map((image, index) => (
                          <div
                            key={index}
                            className="gradient-overlay bottom-0 start-0 h-100"
                          >
                            <img
                              src={image.cdn_image_small}
                              alt={`Restaurant Image ${index + 1}`}
                              className="object-fit-contain"
                              style={{ height: "455px", width: "768px" }}
                            />
                          </div>
                        ))}
                      
                      </Slider>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="d-flex align-items-center gap-2">
            <span>Show</span>
            <select
              className="form-select form-select-sm w-auto"
              defaultValue={limit}
              onChange={(e) => setLimit(parseInt(e.target.value))}
            >
              <option value={limit} disabled>
                {limit}
              </option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div className="icon-field">
            <input
              type="text"
              name="#0"
              className="form-control form-control-sm w-auto"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
            <span className="icon">
              <Icon icon="ion:search-outline" />
            </span>
          </div>
        </div>
      </div>

      <div className="card-body p-24">
        <div className="table-responsive scroll-sm">
          <table className="table bordered-table sm-table mb-0">
            <thead>
              <tr>
                <th scope="col">
                  <div className="d-flex align-items-center gap-10">
                    <div className="form-check style-check d-flex align-items-center"></div>
                    ID
                  </div>
                </th>

                <th scope="col">Logo</th>
                {/* <th scope="col">User</th> */}
                <th scope="col">Name</th>
                <th scope="col">
                  Images
                </th>
                {/* <th scope="col">
                  Branches
                </th> */}
                <th scope="col">
                  Category
                </th>
               
                {/* <th scope="col" className="text-center">
                  Status
                </th> */}
                <th scope="col" className="dt-orderable-asc dt-orderable-desc">
                  Date Added
                </th>
                <th scope="col" className="text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {records &&
                records.map((user) => (
                  <tr key={user.id}>
                    <td>
                      <div className="d-flex align-items-center gap-10">
                        {user.id}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        {user.files.length > 0 ? (
                          <img
                            src={
                              user.files[0]
                                ? user.files[0].cdn_image_small
                                : "no image"
                            }
                            className="w-120-px h-80-px radius-8 object-fit-cover"
                            alt="Avatar"
                          />
                        ) : (
                          <img
                            src="assets/images/avatar/avatar2.png"
                            className="w-120-px h-80-px radius-8 object-fit-cover"
                            alt="Avatar"
                          />
                        )}
                      </div>
                    </td>
                    {/* <td>
                      <span className="text-md mb-0 fw-normal text-secondary-light">
                        {user.user_name}
                      </span>
                    </td> */}

                    <td>
                      <span className="text-md mb-0 fw-normal text-secondary-light">
                        {user.restaurant_name}
                      </span>
                    </td>
                    <td >
                      <span
                        className="bg-success-focus text-success-600 border border-success-main px-24 py-4 radius-4 fw-medium text-sm"
                        type="button"
                        onClick={() =>
                          handleImageModalOpen(
                            user.id,

                            user.files
                          )
                        }
                      >
                        View Images
                      </span>
                    </td>
                    {/* <td>
                     {user.no_of_branches}
                    </td> */}
                    <td>
                      <div className="">
                        <span
                          className="my-tooltip-button badge text-sm fw-semibold text-primary-700 bg-primary-100 px-20 py-9 radius-4 text-white"
                          type="button"
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          data-bs-custom-class="tooltip-primary"
                          title="Tooltip Content Example"
                        >
                          View
                        </span>

                        <div className="my-tooltip tip-content hidden">
                          {/* <h6 className="text-white">
                            Tooltip Title {user.id}
                          </h6> */}
                          <ul>
                            {user.restaurant_categories &&
                              user.restaurant_categories.map((cat) => (
                                <li>{cat.restaurant_category_name}</li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </td>
                  
                    {/* <td className="text-center">
                      {user.active === 1 ? (
                        <span className="bg-success-focus text-success-600 border border-success-main px-24 py-4 radius-4 fw-medium text-sm">
                          Active
                        </span>
                      ) : (
                        <span className="bg-neutral-200 text-neutral-600 border border-neutral-400 px-24 py-4 radius-4 fw-medium text-sm">
                          Inactive
                        </span>
                      )}
                    </td> */}
                    <td>
                      {format(
                        user.updated_at ? user.updated_at : user.created_at,
                        "dd MMM, yyyy"
                      )}
                    </td>
                    <td className="text-center">
                      <div className="d-flex align-items-center gap-10 justify-content-center">
                              <button
                                                  type="button"
                                                  className="bg-success-focus text-success-600 bg-hover-success-200 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle"
                                                >
                                                  <Icon
                                                    icon="mdi:approve"
                                                    className="icon text-xl"
                                                    onClick={() => handleApprove(1, user.id)}
                                                  />
                                                </button>
                        
                                                <button
                                                  type="button"
                                                  onClick={() => handleModalOpen(user.id)}
                                                  className="remove-item-btn bg-danger-focus bg-hover-danger-200 text-danger-600 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle"
                                                >
                                                  <Icon
                                                    icon="charm:circle-cross"
                                                    className="menu-icon"
                                                  />
                                                </button>
                        <button
                          type="button"
                          onClick={() => handleDelete(user.id)}
                          className="remove-item-btn bg-danger-focus bg-hover-danger-200 text-danger-600 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle"
                        >
                          <Icon
                            icon="fluent:delete-24-regular"
                            className="menu-icon"
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 mt-24">
          <span>
            Showing {limit} of {total} entries
          </span>
          <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center">
            {pendingRestaurantPage > 1 ? (
              <li className="page-item">
                <button
                  className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px  text-md"
                  onClick={
                    pendingRestaurantPage > 1
                      ? () =>
                          setPendingRestaurantPage(pendingRestaurantPage - 1)
                      : () => setPendingRestaurantPage(pendingRestaurantPage)
                  }
                >
                  <Icon icon="ep:d-arrow-left" className="" />
                </button>
              </li>
            ) : (
              <></>
            )}

            {pendingRestaurantPage > 2 ? (
              <li className="page-item">
                <button
                  className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md"
                  onClick={() =>
                    setPendingRestaurantPage(pendingRestaurantPage - 2)
                  }
                >
                  {pendingRestaurantPage - 2}
                </button>
              </li>
            ) : (
              <></>
            )}
            {pendingRestaurantPage > 1 ? (
              <li className="page-item">
                <button
                  className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md"
                  onClick={() =>
                    setPendingRestaurantPage(pendingRestaurantPage - 1)
                  }
                >
                  {pendingRestaurantPage - 1}
                </button>
              </li>
            ) : (
              <></>
            )}

            <li className="page-item">
              <Link
                className="page-link text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md bg-primary-600 text-white"
                to="#"
              >
                {pendingRestaurantPage}
              </Link>
            </li>

            {pendingRestaurantPage < lastPage ? (
              <li className="page-item">
                <button
                  className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md"
                  onClick={() =>
                    setPendingRestaurantPage(pendingRestaurantPage + 1)
                  }
                >
                  {pendingRestaurantPage + 1}
                </button>
              </li>
            ) : (
              <></>
            )}
            {pendingRestaurantPage + 1 < lastPage ? (
              <li className="page-item">
                <button
                  className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md"
                  onClick={() =>
                    setPendingRestaurantPage(pendingRestaurantPage + 2)
                  }
                >
                  {pendingRestaurantPage + 2}
                </button>
              </li>
            ) : (
              <></>
            )}
            {pendingRestaurantPage !== lastPage ? (
              <li className="page-item">
                <button
                  className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px  text-md"
                  onClick={() =>
                    setPendingRestaurantPage(pendingRestaurantPage + 1)
                  }
                >
                  {" "}
                  <Icon icon="ep:d-arrow-right" className="" />{" "}
                </button>
              </li>
            ) : (
              <></>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AllPendingRestaurantsLayer;
