import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";

import Breadcrumb from "../../components/Breadcrumb";

import AllRecipeCategoriesLayer from "../../components/recipeCategory/AllRecipeCategoriesLayer";


const AllRecipeCategoryPage = () => {
  return (
    <>

      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="All Recipe Categories" />

        {/* TableDataLayer */}
        <AllRecipeCategoriesLayer />
        {/* <AlertLayer/> */}
    

      </MasterLayout>

    </>
  );
};

export default AllRecipeCategoryPage; 
