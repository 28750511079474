import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";

import Breadcrumb from "../../components/Breadcrumb";

import AllDishCategoriesLayer from "../../components/dishCategory/AllDishCategoriesLayer";


const AllDishCategoryPage = () => {
  return (
    <>

      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="All Dish Categories" />

        {/* TableDataLayer */}
        <AllDishCategoriesLayer />
        {/* <AlertLayer/> */}
    

      </MasterLayout>

    </>
  );
};

export default AllDishCategoryPage; 
