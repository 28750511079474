import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";
import AllMenusLayer from "../../components/restaurants/AllMenusLayer";


const AllMenusPage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Menus" />

        {/* AddUserLayer */}
        <AllMenusLayer />


      </MasterLayout>
    </>
  );
};

export default AllMenusPage;
