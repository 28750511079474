import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";

import AddRestaurantCategoryLayer from "../../components/restaurantCategory/AddRestaurantCategoryLayer";


const AddRestaurantCategoryPage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Add Restaurant Category" />

        {/* AddUserLayer */}
        <AddRestaurantCategoryLayer />


      </MasterLayout>
    </>
  );
};

export default AddRestaurantCategoryPage;
