import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";

import Breadcrumb from "../../components/Breadcrumb";
import AllBanksLayer from "../../components/banks/AllBanksLayer";


const AllBanksPage = () => {
  return (
    <>

      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="All Banks" />

        {/* TableDataLayer */}
        <AllBanksLayer />
        {/* <AlertLayer/> */}
    

      </MasterLayout>

    </>
  );
};

export default AllBanksPage; 
