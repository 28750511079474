import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";

import EditDishCuisineLayer from "../../components/dishCuisine/EditDishCuisineLayer";


const EditDishCuisinePage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Edit Dish Cuisine" />

        {/* AddUserLayer */}
        <EditDishCuisineLayer />


      </MasterLayout>
    </>
  );
};

export default EditDishCuisinePage;
