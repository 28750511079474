import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";

import EditRestaurantCategoryLayer from "../../components/restaurantCategory/EditRestaurantCategoryLayer";


const EditRestaurantCategoryPage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Edit Restaurant Category" />

        {/* AddUserLayer */}
        <EditRestaurantCategoryLayer />


      </MasterLayout>
    </>
  );
};

export default EditRestaurantCategoryPage;
