import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";
import AllFeaturedRestaurantsLayer from "../../components/restaurants/AllFeaturedRestaurantsLayer";


const FeaturedRestaurantsPage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Featured Restaurant Locations" />

        {/* AddUserLayer */}
        <AllFeaturedRestaurantsLayer />


      </MasterLayout>
    </>
  );
};

export default FeaturedRestaurantsPage;
