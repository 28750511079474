import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";
import EditCardLayer from "../../components/banks/EditCardLayer";


const EditCardPage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Edit Card" />

        {/* AddUserLayer */}
        <EditCardLayer />


      </MasterLayout>
    </>
  );
};

export default EditCardPage;
