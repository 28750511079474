import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";
import AddLocationLayer from "../../components/restaurants/AddLocationLayer";


const AddLocationPage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Add Location" />

        {/* AddUserLayer */}
        <AddLocationLayer />


      </MasterLayout>
    </>
  );
};

export default AddLocationPage;
