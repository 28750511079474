import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";
import AddDiscountLayer from "../../components/restaurants/AddDiscountLayer";


const AddDiscountPage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Add Discount" />

        {/* AddUserLayer */}
        <AddDiscountLayer />


      </MasterLayout>
    </>
  );
};

export default AddDiscountPage;
