import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useState, useContext } from "react";
import Select from "react-select";
import { API_URL } from "../../constants/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./AddUser.css"
import { ModeContext } from "../../App";

const AddUserLayer = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [admin_user_group_id, setGroupId] = useState();
  const [groups, setGroups] = useState([]);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const token = localStorage.getItem("pfp-token");
  const {mode, setMode} = useContext(ModeContext)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await fetch(
          `${API_URL}/api/backend/admin/groups/list`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const json = await response.json();

        if (response.ok) {
          let my_group = json.data.map((group) => ({
            label: group.group_name,
            value: group.id,
          }));

          setGroups(my_group);
        } else {
        }
      } catch (error) {
        console.error(error);
      } finally {
        // Clear form fields if necessary
      }
    };
    fetchGroups();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };


  const validateForm = () => {
    let errors = {}
    if (!admin_user_group_id) {
      errors.group_id = "Group is required"
      
      toast.error("Group required")
    }
    // if (!restaurant_dish_cuisine_id) {
    //   errors.cuisine = "Cuisine is required";
    // }

    
    return Object.keys(errors).length === 0 // Returns true if there are no errors
  }



  const handleSubmit = async e => {
    e.preventDefault()

    await validateForm()
    const data = {
      name,
      email,
      password,
      admin_user_group_id,
    }

    try {
        const loading = toast.loading("Creating user.....")
      const response = await fetch(
        `${API_URL}/api/backend/admin/register`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      )

      const json = await response.json()

      if (response.ok) {

        toast.dismiss(loading)
        toast.success("User created successfully")
        navigate("/all-users")
      } else {
        toast.dismiss(loading)
        toast.error("Error creating user")
      }
    } catch (error) {
     
      console.error(error)

    } finally {
      // Clear form fields if necessary
    }
  }

  const colorStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: '#273142',
      borderColor: state.isFocused ? '#blue' : '#ccc',
      boxShadow: state.isFocused ? '0 0 0 1px #blue' : 'none',
      '&:hover': {
        borderColor: '#blue',
      },
     
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? '#24A97B'
        : state.isFocused
        ? '#273142'
        : '#273142',
      color: state.isSelected ? 'black' : 'white',
      '&:hover': {
        backgroundColor: '#000000',
      },
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: '#ffffff',
      border: '1px solid #ccc',
    }),
    placeholder: (base) => ({
      ...base,
      color: '#ffffff',
    }),
    singleValue: (base) => ({
      ...base,
      color: '#ffffff',
    }),
    input: (base) => ({
      ...base,
      color: '#ffffff', // Set the search text color here
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: '#000000', // Background color for selected values
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: '#ffffff', // Text color for selected value labels
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: '#ffffff', // Text color for the remove icon
      '&:hover': {
        backgroundColor: 'red', // Background color on hover
        color: 'white', // Text color on hover
      },
    }),
  };
  return (
    <div className="card h-100 p-0 radius-12">
      <div className="card-body p-24">
        <div className="row justify-content-center">
          <div className="col-xxl-6 col-xl-8 col-lg-10">
            <div className="card border">
              <div className="card-body">
                <h6 className="text-md text-primary-light mb-16">
                  Profile Image
                </h6>
                {/* Upload Image Start */}
                <div className="mb-24 mt-16">
                  <div className="avatar-upload">
                    <div className="avatar-edit position-absolute bottom-0 end-0 me-24 mt-16 z-1 cursor-pointer">
                      <input
                        type="file"
                        id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                        hidden
                        onChange={handleImageChange}
                      />
                      <label
                        htmlFor="imageUpload"
                        className="w-32-px h-32-px d-flex justify-content-center align-items-center bg-primary-50 text-primary-600 border border-primary-600 bg-hover-primary-100 text-lg rounded-circle"
                      >
                        <Icon
                          icon="solar:camera-outline"
                          className="icon"
                        ></Icon>
                      </label>
                    </div>
                    <div className="avatar-preview">
                      <div
                        id="imagePreview"
                        style={{
                          backgroundImage: imagePreviewUrl
                            ? `url(${imagePreviewUrl})`
                            : "",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                {/* Upload Image End */}
                <form onSubmit={handleSubmit}>
                  <div className="mb-20">
                    <label
                      htmlFor="name"
                      className="form-label fw-semibold text-primary-light text-sm mb-8"
                    >
                      Full Name <span className="text-danger-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control radius-8"
                      id="name"
                      placeholder="Enter Full Name"
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-20">
                    <label
                      htmlFor="email"
                      className="form-label fw-semibold text-primary-light text-sm mb-8"
                    >
                      Email <span className="text-danger-600">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control radius-8"
                      id="email"
                      placeholder="Enter email address"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-20">
                    <label
                      htmlFor="number"
                      className="form-label fw-semibold text-primary-light text-sm mb-8"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control radius-8"
                      id="number"
                      placeholder="Enter password"
                      minLength={6}
                      required
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="mb-20">
                    <label
                      htmlFor="depart"
                      className="form-label fw-semibold text-primary-light text-sm mb-8"
                    >
                      Group
                      <span className="text-danger-600">*</span>{" "}
                    </label>
                    <Select
                      // value={restaurant_category_id}
                     
                      onChange={(e) => setGroupId(e.value)}
                      options={groups}
                      styles={mode !== 'light' ? colorStyles : undefined} 
                      classNamePrefix="select2-selection"
                      validate={{ required: { value: true } }}
                    />
                  </div>

                  <div className="d-flex align-items-center justify-content-center gap-3">
                    <button
                      type="button"
                      className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-56 py-11 radius-8"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary-600 border border-primary-600 text-md px-56 py-12 radius-8"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserLayer;
