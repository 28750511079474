import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";
import AllLocationsLayer from "../../components/restaurants/AllLocationsLayer";


const AllLocationsPage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Locations" />

        {/* AddUserLayer */}
        <AllLocationsLayer />


      </MasterLayout>
    </>
  );
};

export default AllLocationsPage;
