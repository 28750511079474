import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useContext, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { API_URL } from "../../constants/api";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ModeContext } from "../../App";

const EditRestaurantLayer = () => {
  const {id, type} = useParams()
  const token = localStorage.getItem("pfp-token");
  const navigate = useNavigate();
  const [restaurant_name, setName] = useState("");
  const [restaurant_description, setDescription] = useState("");
  const [premium, setPremium] = useState();
  const [status, setActive] = useState();
  const [breakfast, setBreakFast] = useState();
  const [lunch, setLunch] = useState();
  const [dinner, setDinner] = useState();
  const [snack, setSnack] = useState();
  const [dessert, setDesert] = useState();
  const [beverages, setBeverages] = useState();
  const [keywords, setKeywords] = useState("");
  const [no_of_branches, setBranches] = useState(0);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [timing, setTiming] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [restaurant_categories, setRestaurantCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [restaurant_slug, setSlug] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [error, setError] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const { mode, setMode } = useContext(ModeContext);

  useEffect(() => {
    const fetchRestaurant = async () => {
 
      try {
        const response = await fetch(
          `${API_URL}/api/backend/restaurants/${id}`,{
            headers: {
              "Authorization": `Bearer ${token}`,
            }
          }
        )
        const json = await response.json()
        if (response.ok) {
          setName(json.data.restaurant_name || "")
          setKeywords(json.data.keywords || "")
          setSlug(json.data.restaurant_slug || "")
          setDescription(json.data.restaurant_description || "")
          setBranches(json.data.no_of_branches)
          setTiming(json.data.timing)
          setPhoneNumber(json.data.phone_number)
          setRestaurantCategories(json.data.restaurant_categories || [])
          let aqdas = []
          json.data.restaurant_categories.forEach((cat, index)=>(
            
              aqdas[index] = cat.restaurant_category_id
            
          ))

          setRestaurantCategories(aqdas)

          setActive(json.data.active === 1 ? true : false)
          setPremium(json.data.is_premium === 1 ? true : false)
          const newImages = json.data.files.map((file) => ({
            src: file.cdn_image_small,
            file,
          }));
          setUploadedImages((prev) => [...prev, ...newImages]);
          setBreakFast(json.data.has_breakfast === 1 ? true : false)
          setLunch(json.data.has_lunch === 1 ? true : false)
          setDinner(json.data.has_dinner === 1 ? true : false)
          setSnack(json.data.has_snack === 1 ? true : false)
          setDesert(json.data.has_dessert === 1 ? true : false)
          setBeverages(json.data.has_beverages === 1 ? true : false)
    
          // setOldImages(json.data.files)
          // setPreviousFileURL(json.data.category_image_url)
        } else {
          console.error("Failed to fetch category data:", json.message)
        }
      } catch (error) {
        console.error("Error fetching category data:", error)
      }
    }
    fetchRestaurant()
  }, [id])
  useEffect(() => {
    const fetchAllCategories = async () => {
      const searchParams = new URLSearchParams({
        offset: 1,
        limit: 100000,
      });
      const response = await fetch(
        `${API_URL}/api/backend/restaurants/category/list?${searchParams}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const json = await response.json();

      if (response.ok) {
        const options = json.data.map((cat) => ({
          label: cat.category_name,
          value: cat.id,
        }));
        setCategories(options || []);
      }
    };

    fetchAllCategories();
  }, []);

  // Function to fetch slug from the API
  const fetchSlug = async (name) => {
    try {
      const params = new URLSearchParams({
        slug_string: name,
      });
      const response = await fetch(
        `${API_URL}/api/backend/slug/generate?${params}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const json = await response.json();

      if (response.ok) {
        setSlug(json.data.slug);
        console.log(json.data.slug);
      } else {
        console.error("Failed to generate slug");
      }
    } catch (error) {
      console.error("Error fetching slug:", error);
    }
  };
  // bank image handling

  // Handle when the input field loses focus (onBlur event)
  const handleBlur = () => {
    if (restaurant_name) {
      fetchSlug(restaurant_name);
    }
  };
  const handleCategoryChange = (selectedOptions) => {
    setRestaurantCategories(
      selectedOptions ? selectedOptions.map((opt) => opt.value) : []
    );
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.map((file) => ({
      src: URL.createObjectURL(file),
      file,
    }));
    setUploadedImages((prev) => [...prev, ...newImages]);
    e.target.value = "";
  };

  const removeImage = (src) => {
    setUploadedImages((prev) => prev.filter((image) => image.src !== src));
    URL.revokeObjectURL(src);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let active = status === true ? 1 : 0;
    let is_premium = premium === true ? 1 : 0;

    let has_breakfast = breakfast === true ? 1 : 0;
    let has_lunch = lunch === true ? 1 : 0;
    let has_dinner = dinner === true ? 1 : 0;
    let has_snack = snack === true ? 1 : 0;
    let has_dessert = dessert === true ? 1 : 0;
    let has_beverages = beverages === true ? 1 : 0;

    const formData = new FormData();

    formData.append("restaurant_name", restaurant_name);
    formData.append("restaurant_slug", restaurant_slug);
    formData.append("keywords", keywords);
    formData.append("restaurant_description", restaurant_description);
    // formData.append("restaurant_category_id", restaurant_category_id)
    formData.append("active", active);
    formData.append("no_of_branches", no_of_branches);
    formData.append("timing", timing);
    formData.append("phone_number", phone_number);
    formData.append("is_premium", is_premium);

    formData.append("has_breakfast", has_breakfast);
    formData.append("has_lunch", has_lunch);
    formData.append("has_dinner", has_dinner);
    formData.append("has_snack", has_snack);
    formData.append("has_dessert", has_dessert);
    formData.append("has_beverages", has_beverages);

    for (let i = 0; i < restaurant_categories.length; i++) {
      formData.append(`restaurant_categories[${i}]`, restaurant_categories[i]);
    }

    // if (uploadedImages.length > 0) {
    //   uploadedImages.forEach((file) => {
    //     formData.append("images[]", file.file); 
    //   });
    // }

    try {
      const loading = toast.loading("Updating restaurant...");
      const response = await fetch(`${API_URL}/api/backend/restaurants/update/${id}`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const json = await response.json();

      if (response.ok) {
        toast.dismiss(loading);
        toast.success("Restaurant Updated Successfully!");
        navigate("/all-restaurants");
      } else {
        if (json.message) {
          toast.dismiss(loading);
          toast.error(json.message);
          setError(json.message);
        }

        if (json.errors.restaurant_categories) {
          toast.dismiss(loading);
          toast.error(json.errors.restaurant_categories[0]);
          setAlertVisible(true);
        }

        setTimeout(() => setAlertVisible(false), 10000);
      }
    } catch (error) {
      console.error(error);
    } finally {
      // Clear form fields if necessary
    }
  };
  const colorStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "#273142",
      borderColor: state.isFocused ? "#blue" : "#ccc",
      boxShadow: state.isFocused ? "0 0 0 1px #blue" : "none",
      "&:hover": {
        borderColor: "#blue",
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? "#24A97B"
        : state.isFocused
        ? "#273142"
        : "#273142",
      color: state.isSelected ? "black" : "white",
      "&:hover": {
        backgroundColor: "#000000",
      },
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "#ffffff",
      border: "1px solid #ccc",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#ffffff",
    }),
    singleValue: (base) => ({
      ...base,
      color: "#ffffff",
    }),
    input: (base) => ({
      ...base,
      color: "#ffffff", // Set the search text color here
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: "#000000", // Background color for selected values
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: "#ffffff", // Text color for selected value labels
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: "#ffffff", // Text color for the remove icon
      "&:hover": {
        backgroundColor: "red", // Background color on hover
        color: "white", // Text color on hover
      },
    }),
  };
  return (
    <div className="card h-100 p-0 radius-12 overflow-hidden">
      <div className="card-body p-40">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-4">
              <div className="mb-20">
                <label
                  htmlFor="name"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Name <span className="text-danger-600">*</span>
                </label>
                <input
                  type="text"
                  className="form-control radius-8"
                  id="name"
                  placeholder="Enter restaurant name"
                  onChange={(e) => setName(e.target.value)}
                  required
                  onBlur={handleBlur}
                  value={restaurant_name}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mb-20">
                <label
                  htmlFor="name"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Slug <span className="text-danger-600">*</span>
                </label>
                <input
                  type="text"
                  className="form-control radius-8"
                  id="name"
                  placeholder="Enter restaurant slug"
                  onChange={(e) => setSlug(e.target.value)}
                  required
                  value={restaurant_slug}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mb-20">
                <label
                  htmlFor="depart"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Choose Category
                  <span className="text-danger-600">*</span>{" "}
                </label>
                <Select
                  isMulti
                  onChange={handleCategoryChange}
                  options={categories}
                  styles={mode !== "light" ? colorStyles : undefined}
                  classNamePrefix="select2-selection"
                  value={restaurant_categories.map((already)=>
                    categories.find((cat)=> cat.value === already)
                  )}
                  validate={{ required: { value: true } }}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mb-20">
                <label
                  htmlFor="name"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Timing
                </label>
                <input
                  type="text"
                  className="form-control radius-8"
                  id="name"
                  placeholder="Enter restaurant timing"
                  onChange={(e) => setTiming(e.target.value)}
                  value={timing}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mb-20">
                <label
                  htmlFor="name"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Keywords
                </label>
                <input
                  type="text"
                  className="form-control radius-8"
                  id="name"
                  placeholder="Separate by commas"
                  onChange={(e) => setKeywords(e.target.value)}
                  value={keywords}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-20">
                <label
                  htmlFor="email"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Restaurant Images
                </label>
                <div className="upload-image-wrapper d-flex align-items-center gap-3 flex-wrap">
                  <div className="uploaded-imgs-container d-flex gap-3 flex-wrap">
                    {uploadedImages.map((image, index) => (
                      <div
                        key={index}
                        className="position-relative h-120-px w-120-px border input-form-light radius-8 overflow-hidden border-dashed bg-neutral-50"
                      >
                        <button
                          type="button"
                          className="uploaded-img__remove position-absolute top-0 end-0 z-1 text-2xxl line-height-1 me-8 mt-8 d-flex"
                          onClick={() => removeImage(image.src)}
                        >
                          <Icon
                            icon="radix-icons:cross-2"
                            className="text-xl text-danger-600"
                          ></Icon>
                        </button>
                        <img
                          className="w-100 h-100 object-fit-cover"
                          src={image.src}
                          alt="Uploaded Preview"
                        />
                      </div>
                    ))}
                  </div>

                  <label
                    className="upload-file-multiple h-120-px w-120-px border input-form-light radius-8 overflow-hidden border-dashed bg-neutral-50 bg-hover-neutral-200 d-flex align-items-center flex-column justify-content-center gap-1"
                    htmlFor="upload-file-multiple"
                  >
                    <Icon
                      icon="solar:camera-outline"
                      className="text-xl text-secondary-light"
                    ></Icon>
                    <span className="fw-semibold text-secondary-light">
                      Upload
                    </span>
                    <input
                      id="upload-file-multiple"
                      type="file"
                      hidden
                      multiple
                      onChange={handleFileChange}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="mb-20">
                <label
                  htmlFor="description"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Restaurant Description
                </label>
                <textarea
                  type="text"
                  className="form-control radius-8"
                  id="description"
                  placeholder="Enter restaurant description"
                  onChange={(e) => setDescription(e.target.value)}
                  rows={5}
                  value={restaurant_description}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mb-20">
                <label
                  htmlFor="name"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  No. Of Branches
                </label>
                <input
                  type="number"
                  min={0}
                  className="form-control radius-8"
                  id="name"
                  placeholder="Please provide number of branches."
                  onChange={(e) => setBranches(e.target.value)}
                  value={no_of_branches}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mb-20">
                <label
                  htmlFor="name"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Phone Number
                </label>
                <input
                  type="text"
                  min={0}
                  className="form-control radius-8"
                  id="name"
                  placeholder="03001243121"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  value={phone_number}
                />
              </div>
            </div>

            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  checked={premium}
                  onClick={e => setPremium(!premium)}
                  id="two"
                />
              </div>
              <label
                htmlFor="two"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
                Premium
              </label>
            </div>
            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  checked={breakfast}
                  onClick={e => setBreakFast(!breakfast)}
                  id="three"
                />
              </div>
              <label
                htmlFor="three"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
                Breakfast
              </label>
            </div>
            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  checked={lunch}
                  // defaultChecked={featured}
                  onClick={e => setLunch(!lunch)}
                  id="four"
                />
              </div>
              <label
                htmlFor="four"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
                Lunch
              </label>
            </div>
            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  checked={dinner}
                  // defaultChecked={featured}
                  onClick={e => setDinner(!dinner)}
                  id="five"
                />
              </div>
              <label
                htmlFor="five"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
                Dinner
              </label>
            </div>
            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  checked={snack}
                  // defaultChecked={featured}
                  onClick={e => setSnack(!snack)}
                  id="six"
                />
              </div>
              <label
                htmlFor="six"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
                Snack
              </label>
            </div>
            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  checked={dessert}
                  // defaultChecked={featured}
                  onClick={e => setDesert(!dessert)}
                  id="seven"
                />
              </div>
              <label
                htmlFor="seven"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
                Dessert
              </label>
            </div>
            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  checked={beverages}
                  // defaultChecked={featured}
                  onClick={e => setBeverages(!beverages)}
                  id="eight"
                />
              </div>
              <label
                htmlFor="eight"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
               Beverages
              </label>
            </div>
            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  checked={status}
                  onClick={(e) => setActive(!status)}
                  id="one"
                />
              </div>
              <label
                htmlFor="one"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
                Active
              </label>
            </div>

            <div className="d-flex align-items-center justify-content-center gap-3 mt-24">
              <button
                type="submit"
                className="btn btn-primary-600 border border-primary-600 text-md px-24 py-12 radius-8"
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditRestaurantLayer;
