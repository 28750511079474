import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";
import AddPhaseLayer from "../../components/locations/AddPhaseLayer";


const AddPhasePage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Add Phase" />

        {/* AddUserLayer */}
        <AddPhaseLayer />


      </MasterLayout>
    </>
  );
};

export default AddPhasePage;
