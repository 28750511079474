import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useContext, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { API_URL } from "../../constants/api";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ModeContext } from "../../App";

const AddLocationLayer = () => {
  const { id } = useParams();
  const token = localStorage.getItem("pfp-token");
  const navigate = useNavigate();

  const [location_address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState("");
  const [youtube_url, setYoutube] = useState("");
  const [location_country_id, setCountryId] = useState();
  const [countries, setCountries] = useState([]);
  const [location_state_id, setStateId] = useState();
  const [states, setStates] = useState([]);
  const [location_city_id, setCityId] = useState();
  const [cities, setCities] = useState([]);
  const [location_area_id, setAreaId] = useState();
  const [areas, setAreas] = useState([]);
  const [location_phase_id, setPhaseId] = useState();
  const [phases, setPhases] = useState([]);
  const [child_friendly_dining, setChildFriendly] = useState(false);
  const [adult_friendly_dining, setAdultFriendly] = useState(false);
  const [outdoor_sitting, setOutdoorSitting] = useState(false);
  const [indoor_sitting, setIndoorSitting] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const [valet_service, setValet] = useState(false);
  const [play_area, setPlay] = useState(false);
  const [high_chair, setHighChair] = useState(false);
  const [wifi, setWifi] = useState(false);
  const [featured, setFeatured] = useState(false);
  const [live_music, setLiveMusic] = useState(false);
  const [status, setActive] = useState(true);
  const [sort_order, setSort] = useState(0);

  const [validationErrors, setValidationErrors] = useState({});
  const { mode, setMode } = useContext(ModeContext);
  useEffect(() => {
    const fetchAllCountry = async () => {
      try {
        const response = await fetch(
          `${API_URL}/api/backend/locations/countries/list`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const json = await response.json();

        if (response.ok) {
          const options = json.data.map((cat) => ({
            label: cat.country_name,
            value: cat.id,
          }));
          setCountries(options || []);
        }
      } catch (error) {}
    };

    fetchAllCountry();
  }, []);
  useEffect(() => {
    const fetchAllStates = async () => {
      const params = new URLSearchParams({
        location_country_id: location_country_id,
      });

      const response = await fetch(
        `${API_URL}/api/backend/locations/states/list?${params}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const json = await response.json();

      if (response.ok) {
        const options = json.data.map((st) => ({
          label: st.state_name,
          value: st.id,
        }));
        setStates(options || []);
      }
    };
    if (location_country_id) {
      fetchAllStates();
    }
  }, [location_country_id]);
  useEffect(() => {
    const fetchAllCities = async () => {
      const params = new URLSearchParams({
        location_country_id: location_country_id,
        location_state_id: location_state_id,
      });

      const response = await fetch(
        `${API_URL}/api/backend/locations/cities/list?${params}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const json = await response.json();

      if (response.ok) {
        const options = json.data.map((ct) => ({
          label: ct.city_name,
          value: ct.id,
        }));
        setCities(options || []);
      }
    };
    if (location_state_id) {
      fetchAllCities();
    }
  }, [location_state_id]);

  useEffect(() => {
    const fetchAllAreas = async () => {
      const params = new URLSearchParams({
        location_country_id: location_country_id,
        location_state_id: location_state_id,
        location_city_id: location_city_id,
        offset: 1,
        limit: 100000,
      });

      const response = await fetch(
        `${API_URL}/api/backend/locations/areas/list?${params}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const json = await response.json();

      if (response.ok) {
        const options = json.data.map((ar) => ({
          label: ar.area_name,
          value: ar.id,
        }));
        setAreas(options || []);
      }
    };
    if (location_city_id) {
      fetchAllAreas();
    }
  }, [location_city_id]);
  useEffect(() => {
    const fetchAllPhases = async () => {
      setPhases([]);
      const params = new URLSearchParams({
        location_country_id: location_city_id,
        location_state_id: location_state_id,
        location_city_id: location_city_id,
        location_area_id: location_area_id,
        offset: 1,
        limit: 100000,
      });

      const response = await fetch(
        `${API_URL}/api/backend/locations/phases/list?${params}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const json = await response.json();

      if (response.ok) {
        const options = json.data.map((ph) => ({
          label: ph.phase_name,
          value: ph.id,
        }));
        setPhases(options || []);
      }
    };
    if (location_area_id) {
      fetchAllPhases();
    }
  }, [location_area_id]);
  // Validation for Select Fields

  const validateForm = () => {
    let errors = {};
    if (!location_country_id) {
      toast.error("Country is required");
    }
    if (!location_state_id) {
      toast.error("State is required");
    }
    if (!location_city_id) {
      toast.error("City is required");
    }
    if (!location_area_id) {
      toast.error("Area is required");
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0; // Returns true if there are no errors
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    await validateForm();
    let active = status === true ? 1 : 0;
    let is_child_friendly_dining = child_friendly_dining === true ? 1 : 0;
    let is_adult_friendly_dining = adult_friendly_dining === true ? 1 : 0;
    let has_outdoor_sitting = outdoor_sitting === true ? 1 : 0;
    let has_indoor_sitting = indoor_sitting === true ? 1 : 0;
    let has_delivery = delivery === true ? 1 : 0;
    let has_valet_service = valet_service === true ? 1 : 0;
    let has_play_area = play_area === true ? 1 : 0;
    let has_high_chair = high_chair === true ? 1 : 0;
    let has_wifi = wifi === true ? 1 : 0;
    let is_featured = featured === true ? 1 : 0;
    let has_live_music = live_music === true ? 1 : 0;

    const restaurant_id = id;
    // Proceed with form submission if validation passes
    const data = {
      restaurant_id,
      location_address,
      location_country_id,
      location_state_id,
      location_city_id,
      location_area_id,
      location_phase_id,
      coordinates,
      youtube_url,
      sort_order,
      active,
      is_child_friendly_dining,
      is_adult_friendly_dining,
      has_outdoor_sitting,
      has_indoor_sitting,
      has_delivery,
      has_valet_service,
      has_play_area,
      has_high_chair,
      has_wifi,
      is_featured,
      has_live_music,
    };

    try {
      const loading = toast.loading("Adding new location");
      const response = await fetch(
        `${API_URL}/api/backend/restaurant/locations/add`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        toast.dismiss(loading);
        toast.success("Location added successfully!");
        navigate(`/all-locations/${id}`);
      } else {
        toast.dismiss(loading);
        toast.error("Error adding location!");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const colorStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "#273142",
      borderColor: state.isFocused ? "#blue" : "#ccc",
      boxShadow: state.isFocused ? "0 0 0 1px #blue" : "none",
      "&:hover": {
        borderColor: "#blue",
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? "#24A97B"
        : state.isFocused
        ? "#273142"
        : "#273142",
      color: state.isSelected ? "black" : "white",
      "&:hover": {
        backgroundColor: "#000000",
      },
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "#ffffff",
      border: "1px solid #ccc",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#ffffff",
    }),
    singleValue: (base) => ({
      ...base,
      color: "#ffffff",
    }),
    input: (base) => ({
      ...base,
      color: "#ffffff", // Set the search text color here
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: "#000000", // Background color for selected values
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: "#ffffff", // Text color for selected value labels
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: "#ffffff", // Text color for the remove icon
      "&:hover": {
        backgroundColor: "red", // Background color on hover
        color: "white", // Text color on hover
      },
    }),
  };
  return (
    <div className="card h-100 p-0 radius-12 overflow-hidden">
      <div className="card-body p-40">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-4">
              <div className="mb-20">
                <label
                  htmlFor="depart"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Choose Country
                  <span className="text-danger-600">*</span>{" "}
                </label>
                <Select
                  onChange={(e) => setCountryId(e.value)}
                  options={countries}
                  styles={mode !== "light" ? colorStyles : undefined}
                  classNamePrefix="select2-selection"
                  validate={{ required: { value: true } }}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mb-20">
                <label
                  htmlFor="depart"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Choose State
                  <span className="text-danger-600">*</span>{" "}
                </label>
                <Select
                  onChange={(e) => setStateId(e.value)}
                  options={states}
                  styles={mode !== "light" ? colorStyles : undefined}
                  classNamePrefix="select2-selection"
                  validate={{ required: { value: true } }}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mb-20">
                <label
                  htmlFor="depart"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Choose City
                  <span className="text-danger-600">*</span>{" "}
                </label>
                <Select
                  onChange={(e) => setCityId(e.value)}
                  options={cities}
                  styles={mode !== "light" ? colorStyles : undefined}
                  classNamePrefix="select2-selection"
                  validate={{ required: { value: true } }}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mb-20">
                <label
                  htmlFor="depart"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Choose Area
                  <span className="text-danger-600">*</span>{" "}
                </label>
                <Select
                  onChange={(e) => setAreaId(e.value)}
                  options={areas}
                  styles={mode !== "light" ? colorStyles : undefined}
                  classNamePrefix="select2-selection"
                  validate={{ required: { value: true } }}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mb-20">
                <label
                  htmlFor="depart"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Choose Phase
                </label>
                <Select
                  onChange={(e) => setPhaseId(e.value)}
                  options={phases}
                  styles={mode !== "light" ? colorStyles : undefined}
                  classNamePrefix="select2-selection"
                  validate={{ required: { value: true } }}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mb-20">
                <label
                  htmlFor="name"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Location Address
                </label>
                <input
                  className="form-control radius-8"
                  id="name"
                  placeholder="Location Address"
                  onChange={(e) => setAddress(e.target.value)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mb-20">
                <label
                  htmlFor="name"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Add Coordinates
                </label>
                <input
                  className="form-control radius-8"
                  id="name"
                  placeholder="23.12345678, 76.87654321"
                  onChange={(e) => setCoordinates(e.target.value)}
                  type="text"
                  required
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mb-20">
                <label
                  htmlFor="name"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Youtube Video URL
                </label>
                <input
                  className="form-control radius-8"
                  id="name"
                  placeholder="Enter Youtube URL"
                  onChange={(e) => setYoutube(e.target.value)}
                  type="text"
                />
              </div>
            </div>

            <div className="col-sm-4">
              <div className="mb-20">
                <label
                  htmlFor="name"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Sort Order
                </label>
                <input
                  className="form-control radius-8"
                  id="name"
                  placeholder="Enter Sort Number"
                  onChange={(e) => setSort(e.target.value)}
                  type="number"
                  min={0}
                />
              </div>
            </div>

            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  defaultChecked={featured}
                  onChange={(e) => setFeatured(e.target.checked)}
                  id="two"
                />
              </div>
              <label
                htmlFor="two"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
                Featured
              </label>
            </div>

            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  id="three"
                  defaultChecked={child_friendly_dining}
                  onChange={e => setChildFriendly(e.target.checked)}
                />
              </div>
              <label
                htmlFor="three"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
                Child Friendly Dinning
              </label>
            </div>

            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  id="four"
                  defaultChecked={adult_friendly_dining}
                  onChange={e => setAdultFriendly(e.target.checked)}
                />
              </div>
              <label
                htmlFor="four"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
                Adult Friendly Dinning
              </label>
            </div>

            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  id="five"
                  defaultChecked={outdoor_sitting}
                  onChange={e => setOutdoorSitting(e.target.checked)}
                />
              </div>
              <label
                htmlFor="five"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
                Outdoor Sitting
              </label>
            </div>

            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  id="six"
                  defaultChecked={indoor_sitting}
                  onChange={e => setIndoorSitting(e.target.checked)}
                />
              </div>
              <label
                htmlFor="six"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
                Indoor Sitting
              </label>
            </div>

            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  id="seven"
                  defaultChecked={delivery}
                  onChange={e => setDelivery(e.target.checked)}
                />
              </div>
              <label
                htmlFor="seven"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
                Delivery
              </label>
            </div>

            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  id="eight"
                  defaultChecked={valet_service}
                  onChange={e => setValet(e.target.checked)}
                />
              </div>
              <label
                htmlFor="eight"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
                Valet Service
              </label>
            </div>

            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  id="nine"
                  defaultChecked={play_area}
                  onChange={e => setPlay(e.target.checked)}
                />
              </div>
              <label
                htmlFor="nine"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
                Play Area
              </label>
            </div>

            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  id="ten"
                  defaultChecked={high_chair}
                  onChange={e => setHighChair(e.target.checked)}
                />
              </div>
              <label
                htmlFor="ten"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
                High Chair
              </label>
            </div>

            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  id="eleven"
                  defaultChecked={wifi}
                  onChange={e => setWifi(e.target.checked)}
                />
              </div>
              <label
                htmlFor="eleven"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
                Wifi Available
              </label>
            </div>

            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  id="twelve"
                  defaultChecked={live_music}
                  onChange={e => setLiveMusic(e.target.checked)}
                />
              </div>
              <label
                htmlFor="twelve"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
                Live Music
              </label>
            </div>

            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  onChange={(e) => setActive(e.target.checked)}
                  defaultChecked={status}
                  id="one"
                />
              </div>
              <label
                htmlFor="one"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
                Active
              </label>
            </div>

            <div className="d-flex align-items-center justify-content-center gap-3 mt-24">
              <button
                type="submit"
                className="btn btn-primary-600 border border-primary-600 text-md px-24 py-12 radius-8"
              >
                Add Location
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddLocationLayer;
