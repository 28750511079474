import React, { createContext, useState } from "react";
import MasterLayout from "../../masterLayout/MasterLayout";

import Breadcrumb from "../../components/Breadcrumb";
import { Icon } from "@iconify/react/dist/iconify.js";
import AllApprovedRestaurantsLayer from "../../components/restaurants/AllApprovedRestaurantsLayer";
import AllPendingRestaurantsLayer from "../../components/restaurants/AllPendingRestaurantsLayer";
import AllRejectedRestaurantsLayer from "../../components/restaurants/AllRejectedRestaurantsLayer";

const RestaurantsTabContext = createContext();

const AllRecipesPage = () => {
  const [updateTab, setUpdateTab] = useState(0);
  
  const changeTab = () => {
    setUpdateTab(updateTab + 1)
 
  }

  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>
        {/* Breadcrumb */}
        <Breadcrumb title="Restaurants" />

        {/* TableDataLayer */}
        <RestaurantsTabContext.Provider value={{updateTab, setUpdateTab}}>
          <ul
            className="nav button-tab nav-pills mb-16"
            id="pills-tab-four"
            role="tablist"
          >
            <li
              className="nav-item"
              role="presentation"
              onClick={changeTab}
            >
              <button
                className="nav-link d-flex align-items-center gap-2 fw-semibold text-primary-light radius-4 px-16 py-10"
                id="pills-button-icon-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-button-icon-home"
                type="button"
                role="tab"
                aria-controls="pills-button-icon-home"
                aria-selected="true"
              >
                <Icon
                  icon="material-symbols:pending-actions"
                  className="text-xl"
                />
                <span className="line-height-1">Pending Restaurants</span>
              </button>
            </li>
            <li
              className="nav-item"
              role="presentation"
              onClick={changeTab}
            >
              <button
                className="nav-link d-flex align-items-center gap-2 fw-semibold text-primary-light radius-4 px-16 py-10 active"
                id="pills-button-icon-details-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-button-icon-details"
                type="button"
                role="tab"
                aria-controls="pills-button-icon-details"
                aria-selected="false"
              >
                <Icon
                  icon="material-symbols:order-approve-outline-sharp"
                  className="text-xl"
                />
                <span className="line-height-1">Approved Restaurants</span>
              </button>
            </li>
            <li
              className="nav-item"
              role="presentation"
              onClick={changeTab}
            >
              <button
                className="nav-link d-flex align-items-center gap-2 fw-semibold text-primary-light radius-4 px-16 py-10"
                id="pills-button-icon-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-button-icon-profile"
                type="button"
                role="tab"
                aria-controls="pills-button-icon-profile"
                aria-selected="false"
              >
                <Icon
                  icon="fluent:text-change-reject-20-filled"
                  className="text-xl"
                />
                <span className="line-height-1">Rejected Restaurants</span>
              </button>
            </li>
          </ul>
          <div className="tab-content" id="pills-tab-fourContent">
            <div
              className="tab-pane fade"
              id="pills-button-icon-home"
              role="tabpanel"
              aria-labelledby="pills-button-icon-home-tab"
              tabIndex={0}
            >

              <AllPendingRestaurantsLayer />
            </div>
            <div
              className="tab-pane fade show active"
              id="pills-button-icon-details"
              role="tabpanel"
              aria-labelledby="pills-button-icon-details-tab"
              tabIndex={0}
            >
                
              <AllApprovedRestaurantsLayer />
         
            </div>
            <div
              className="tab-pane fade"
              id="pills-button-icon-profile"
              role="tabpanel"
              aria-labelledby="pills-button-icon-profile-tab"
              tabIndex={0}
            >
              <AllRejectedRestaurantsLayer />
            </div>
          </div>
          </RestaurantsTabContext.Provider>
        {/* <AlertLayer/> */}
      </MasterLayout>
    </>
  );
};

export default AllRecipesPage;
export { RestaurantsTabContext };
