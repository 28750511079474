import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";
import EditRestaurantLayer from "../../components/restaurants/EditRestaurantLayer";


const EditRestaurantPage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Edit Restaurant" />

        {/* AddUserLayer */}
        <EditRestaurantLayer />


      </MasterLayout>
    </>
  );
};

export default EditRestaurantPage;
