import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";
import AddCardLayer from "../../components/banks/AddCardLayer";


const AddCardPage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Add Card" />

        {/* AddUserLayer */}
        <AddCardLayer />


      </MasterLayout>
    </>
  );
};

export default AddCardPage;
