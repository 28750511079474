import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../constants/api";
import { format } from "date-fns";
import { UserPageContext } from "../../App";
import Swal from "sweetalert2";
import "./AllAdmins.css"


const AllAdminsLayer = () => {

  const [records, setRecords] = useState([]);
  const [data, setData] = useState(null);
  const token = localStorage.getItem("pfp-token");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()


  const [update, setUpdate] = useState(0);
  const { userPage, setUserPage } = useContext(UserPageContext);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [search, setSearch] = useState("");
  const [group, setGroup] = useState(0)



  useEffect(()=>{

    const changeGroup = async () =>{
    
        
        const filteredRecord = await data.filter((user)=>user.admin_user_group_id === group)
        if(filteredRecord){
          setRecords(filteredRecord)

   
    }
  }

    if(data){
      if(group === 0){
        setUpdate(update+1)
      }else{
        changeGroup()
      }
     
    }
  }, [group])




  useEffect(() => {
    const fetchAllAdmins = async () => {
      if (search.trim() !== "") {
        await setLimit(10);
        await setUserPage(1);
      }

      try {
        setLoading(true);

        const searchParams = new URLSearchParams({
          offset: userPage,
          limit: limit,
          search_term: search,
          is_approved: 1,
        });
        const response = await fetch(
          `${API_URL}/api/backend/admin/list?${searchParams}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const json = await response.json();

        if (response.ok) {
          const sortedData = json.data.sort((a, b) =>
            new Date(b.created_at) - new Date(a.created_at)
          );
          setRecords(sortedData);
          setData(sortedData);
          setLastPage(json.pagination.last_page);
          setTotal(json.pagination.total);
        } else {
          setRecords([]);
        }
      } catch (error) {
        alert(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllAdmins();
  }, [update, limit, userPage, search]);

  const handleDelete = myId => {
    console.log(myId)
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ms-2",
        cancelButton: "btn btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(result => {
        if (result.isConfirmed) {
          const deleteUser = async d_id => {
            const response = await fetch(
              `${API_URL}/api/backend/admin/delete/${d_id}`,
              {
                method: "DELETE",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            )
            setUpdate(update + 1)
          }
          deleteUser(myId)
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Role has been deleted.",
            icon: "success",
          })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Role is safe :)",
            icon: "error",
          })
        }
      })
  }

const handleEdit = (id)=>{
  navigate("/edit-user/"+id)
}
  return (
    <div className="card h-100 p-0 radius-12">
      <div className="card-header border-bottom bg-base py-16 px-24 d-flex align-items-center flex-wrap gap-3 justify-content-between">
        <div className="d-flex flex-wrap align-items-center gap-3">
          <div className="d-flex align-items-center gap-2">
            <span>Show</span>
            <select
              className="form-select form-select-sm w-auto"
              defaultValue={limit}
              onChange={(e) => setLimit(parseInt(e.target.value))}
            >
              <option value={limit} disabled>
                {limit}
              </option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div className="icon-field">
            <input
              type="text"
              name="#0"
              className="form-control form-control-sm w-auto"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
            <span className="icon">
              <Icon icon="ion:search-outline" />
            </span>
          </div>
        </div>
        <div className="d-flex flex-wrap align-items-center gap-3">
          <select
            className="form-select form-select-sm w-auto"
            defaultValue={0}
            onChange={(e) => setGroup(parseInt(e.target.value))}
          >
            <option value={0}>
              All Roles
            </option>
            <option value={1}>Admin</option>
            <option value={2}>Data Entry</option>
            <option value={3}>Comment Reviewer</option>
          </select>
          <Link to="/add-user" className="btn btn-sm btn-primary-600">
            <i className="ri-add-line" /> Create a Role
          </Link>
        </div>
      </div>

      <div className="card-body p-24">
        <div className="table-responsive scroll-sm">
          <table className="table bordered-table sm-table mb-0">
            <thead>
              <tr>
                <th scope="col">
                  <div className="d-flex align-items-center gap-10">
                    <div className="form-check style-check d-flex align-items-center">
                  
                    </div>
                    ID
                  </div>
                </th>

                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Mobile</th>
                <th scope="col">Group</th>
                <th scope="col" className="text-center">
                  Status
                </th>
                <th scope="col" className='dt-orderable-asc dt-orderable-desc'>Date Created</th>
                <th scope="col" className="text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {records &&
                records.map((user) => (
                  <tr key={user.id}>
                    <td>
                      <div className="d-flex align-items-center gap-10">
                        {user.id}
                      </div>
                    </td>

                    <td>
                      <div className="d-flex align-items-center">
                        <img
                          src={
                            user.cdn_image_small
                              ? user.cdn_image_small
                              : "assets/images/user-list/user-list1.png"
                          }
                          alt="Wowdash"
                          className="w-40-px h-40-px rounded-circle flex-shrink-0 me-12 overflow-hidden"
                        />
                        <div className="flex-grow-1">
                          <span className="text-md mb-0 fw-normal text-secondary-light">
                            {user.name}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-md mb-0 fw-normal text-secondary-light">
                        {user.email}
                      </span>
                    </td>
                    <td>{user.mobile_no}</td>
                    <td>{user.group_name}</td>
                    <td className="text-center">
                      {user.active === 1 ? (
                        <span className="bg-success-focus text-success-600 border border-success-main px-24 py-4 radius-4 fw-medium text-sm">
                          Active
                        </span>
                      ) : (
                        <span className="bg-neutral-200 text-neutral-600 border border-neutral-400 px-24 py-4 radius-4 fw-medium text-sm">
                          Inactive
                        </span>
                      )}
                    </td>
                    <td>{format(user.created_at, "dd MMM, yyyy")}</td>
                    <td className="text-center">
                      <div className="d-flex align-items-center gap-10 justify-content-center">
                        {/* <button
                          type="button"
                          className="bg-info-focus bg-hover-info-200 text-info-600 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle"
                        >
                          <Icon
                            icon="majesticons:eye-line"
                            className="icon text-xl"
                          />
                        </button> */}
                        <button
                          onClick={()=>handleEdit(user.id)}
                          className="bg-info-focus bg-hover-info-200 text-info-600 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle"
                        >
                          <Icon icon="lucide:edit" className="menu-icon" />
                        </button>
                        <button
                          type="button"
                          onClick={() => handleDelete(user.id)}
                          className="remove-item-btn bg-danger-focus bg-hover-danger-200 text-danger-600 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle"
                        >
                          <Icon
                            icon="fluent:delete-24-regular"
                            className="menu-icon"
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 mt-24">
          <span>
            Showing {limit} of {total} entries
          </span>
          <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center">
            {userPage > 1 ? (
              <li className="page-item">
                <button
                  className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px  text-md"
                  onClick={
                    userPage > 1
                      ? () => setUserPage(userPage - 1)
                      : () => setUserPage(userPage)
                  }
                >
                  <Icon icon="ep:d-arrow-left" className="" />
                </button>
              </li>
            ) : (
              <></>
            )}

            {userPage > 2 ? (
              <li className="page-item">
                <button
                  className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md"
                  onClick={() => setUserPage(userPage - 2)}
                >
                  {userPage - 2}
                </button>
              </li>
            ) : (
              <></>
            )}
            {userPage > 1 ? (
              <li className="page-item">
                <button
                  className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md"
                  onClick={() => setUserPage(userPage - 1)}
                >
                  {userPage - 1}
                </button>
              </li>
            ) : (
              <></>
            )}

            <li className="page-item">
              <Link
                className="page-link text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md bg-primary-600 text-white"
                to="#"
              >
                {userPage}
              </Link>
            </li>

            {userPage < lastPage ? (
            <li className="page-item">
              <button
                className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md"
                onClick={() => setUserPage(userPage + 1)}
              >
                {userPage + 1}
              </button>
            </li>
             ) : (
              <></>
            )}
            {userPage + 1 < lastPage ? (
            <li className="page-item">
              <button
                className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md"
                onClick={() => setUserPage(userPage + 2)}
              >
                {userPage + 2}
              </button>
            </li>
             ) : (
              <></>
            )}
        {userPage !== lastPage ? (
 <li className="page-item">
 <button
   className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px  text-md"
   onClick={() => setUserPage(userPage + 1)}
 >
   {" "}
   <Icon icon="ep:d-arrow-right" className="" />{" "}
 </button>
</li>

) : (
  <></>
)}
           
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AllAdminsLayer;
