import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";

import Breadcrumb from "../../components/Breadcrumb";

import AllDishCuisineLayer from "../../components/dishCuisine/AllDishCuisineLayer";


const AllDishCuisinePage = () => {
  return (
    <>

      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="All Dish Cuisine" />

        {/* TableDataLayer */}
        <AllDishCuisineLayer />
        {/* <AlertLayer/> */}
    

      </MasterLayout>

    </>
  );
};

export default AllDishCuisinePage; 
