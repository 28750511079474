import React, { useEffect, useState } from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";
import DashBoardLayerOne from "../components/DashBoardLayerOne";

const HomePageOne = () => {
  const [iframeUrl, setIframeUrl] = useState("")
  const token = localStorage.getItem("pfp-token")
  const [loading, setLoading] = useState(false)
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    const fetchUrl = async () => {
      console.log("hi")
      try {
        setLoading(true)

        setCounter(0)
        let progress = 0
        const interval = setInterval(() => {
          setCounter(prev => {
            const nextValue = prev + 1
            if (nextValue >= 100) {
              clearInterval(interval)
            }
            return nextValue
          })
        }, 25)
        const response = await fetch(
          "https://api.pakistanfoodportal.com/api/backend/auth/metabase_auth",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        const url = await response.json()

        if (response) {
          setIframeUrl(url.data)
          console.log(url.data)
        }
      } catch (error) {alert(error)}
      finally{
        setCounter(100)
        setLoading(false)
      }
    }

    fetchUrl()
    const interval = setInterval(fetchUrl, 15 * 60 * 1000); // 15 minutes in milliseconds

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [])
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>
        {/* Breadcrumb */}
        <Breadcrumb title="Metabase" />

        {loading ? (

                // <Col lg={12}>
                //   <Progress
                //     striped
                //     animated
                //     color="bg-primary"
                //     value={counter}
                //   />
                // </Col>
                <>Loading</>
              ) : (
              <iframe 

                src={iframeUrl}
                frameBorder={0}
                style={{ width: "100%", height: "80vh" }} // Responsive iframe
                allowTransparency
              />
              )}
        {/* DashBoardLayerOne */}
        {/* <DashBoardLayerOne /> */}

      </MasterLayout>
    </>
  );
};

export default HomePageOne;
