import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";
import AddRecipeCategoryLayer from "../../components/recipeCategory/AddRecipeCategoryLayer";


const AddRecipeCategoryPage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Add Recipe Category" />

        {/* AddUserLayer */}
        <AddRecipeCategoryLayer />


      </MasterLayout>
    </>
  );
};

export default AddRecipeCategoryPage;
