import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { API_URL } from "../../constants/api";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const EditRestaurantCategoryLayer = () => {
  const { id } = useParams()
  const token = localStorage.getItem("pfp-token");
  const navigate = useNavigate();
  const [category_name, setName] = useState("")
  const [category_description, setDescription] = useState("")
  const [category_slug, setSlug] = useState("");
  const [image, setImage] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [status, setActive] = useState();
  const [error, setError] = useState("")
  // bank image handling

  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    if (e.target.files.length) {
      const src = URL.createObjectURL(e.target.files[0]);
      setImagePreview(src);
      setImage(e.target.files[0]);
    }
  };

  const removeImage = () => {
    setImagePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      setImage(null);
    }
  };
  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await fetch(
          `${API_URL}/api/backend/restaurants/category/${id}`,{
            headers: {
              "Authorization": `Bearer ${token}`,
            }
          }
        )
        const json = await response.json()
        if (response.ok) {
          setName(json.data.category_name || "")
          setDescription(json.data.category_description || "")
          setSlug(json.data.category_slug || "")
          setActive(json.data.active === 1)
          setImagePreview(json.data.category_image_url)
        } else {
          console.error("Failed to fetch category data:", json.message)
        }
      } catch (error) {
        console.error("Error fetching category data:", error)
      }
    }
    fetchCategory()
  }, [id])
  useEffect(() => {
    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);

  const fetchSlug = async (name) => {
    try {
      const params = new URLSearchParams({
        slug_string: name
      })
      const response = await fetch(`${API_URL}/api/backend/slug/generate?${params}`, {
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      });
      const json = await response.json();
 
      if (response.ok) {
        setSlug(json.data.slug); 
        console.log(json.data.slug)
      } else {
        console.error("Failed to generate slug");
      }
    } catch (error) {
      console.error("Error fetching slug:", error);
    }
  };

  const handleBlur = () => {
    if (category_name) {
      fetchSlug(category_name);
    }
  };

 

  const handleSubmit = async (e) => {
    e.preventDefault();

  

    let active = 0;
    if (status === true) {
      active = 1;
    } else if (status === false) {
      active = 0;
    }

    const formData = new FormData();

    if (!image) {
      formData.append("category_name", category_name)
      formData.append("category_description", category_description)
      formData.append("category_slug", category_slug)
      formData.append("active", active)
    } else {
      formData.append("category_name", category_name)
      formData.append("category_slug", category_slug)
      formData.append("category_description", category_description)
      formData.append("active", active)
      formData.append("image", image)
    }

    try {
      const loading = toast.loading("Updating category....")
      const response = await fetch(`${API_URL}/api/backend/restaurants/category/update/${id}`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const json = await response.json();

      if (response.ok) {
        toast.dismiss(loading)
        toast.success("Category updated successfully")
        navigate(`/all-restaurant-category`);
        // setAlertVisible(true)
        // setTimeout(() => setAlertVisible(false), 5000)
      }else{
        toast.dismiss(loading)
        toast.error("Error updating category")
        setError(json.message)
      }
    } catch (error) {
    } finally {
    }
  };
  return (
    <div className="card h-100 p-0 radius-12 overflow-hidden">
      <div className="card-body p-40">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-6">
              <div className="mb-20">
                <label
                  htmlFor="name"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Name <span className="text-danger-600">*</span>
                </label>
                <input
                  type="text"
                  className="form-control radius-8"
                  id="name"
                  placeholder="Enter category name"
                  onChange={(e) => setName(e.target.value)}
                  required
                  onBlur={handleBlur}
                  value={category_name}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-20">
                <label
                  htmlFor="name"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Slug <span className="text-danger-600">*</span>
                </label>
                <input
                  type="text"
                  className="form-control radius-8"
                  id="name"
                  placeholder="Enter category slug"
                  onChange={(e) => setSlug(e.target.value)}
                  required
                  value={category_slug}
                />
                   {error.trim() !== "" && (
                        <div className="invalid-feedback d-block">
                          {error}
                        </div>
                      )}
              </div>
            </div>
         
            <div className="col-sm-12">
              <div className="mb-20">
                <label
                  htmlFor="description"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Category Description
                </label>
                <textarea
                  type="text"
                  className="form-control radius-8"
                  id="description"
                  placeholder="Describe the category"
                  onChange={(e) => setDescription(e.target.value)}
                  rows={5}
                  value={category_description}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="mb-20">
                <label
                  htmlFor="email"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Category Image
                </label>
                <div className="upload-image-wrapper d-flex align-items-center gap-3">
                  {/* Image preview section */}
                  {imagePreview ? (
                    <div className="uploaded-img position-relative h-120-px w-120-px border input-form-light radius-8 overflow-hidden border-dashed bg-neutral-50">
                      <button
                        type="button"
                        onClick={removeImage}
                        className="uploaded-img__remove position-absolute top-0 end-0 z-1 text-2xxl line-height-1 me-8 mt-8 d-flex"
                        aria-label="Remove uploaded image"
                      >
                        <Icon
                          icon="radix-icons:cross-2"
                          className="text-xl text-danger-600"
                        ></Icon>
                      </button>
                      <img
                        id="uploaded-img__preview"
                        className="w-100 h-100 object-fit-cover"
                        src={imagePreview}
                        alt="Preview"
                      />
                    </div>
                  ) : (
                    <label
                      className="upload-file h-120-px w-120-px border input-form-light radius-8 overflow-hidden border-dashed bg-neutral-50 bg-hover-neutral-200 d-flex align-items-center flex-column justify-content-center gap-1"
                      htmlFor="upload-file"
                    >
                      <Icon
                        icon="solar:camera-outline"
                        className="text-xl text-secondary-light"
                        onClick={(e) => {
                          e.preventDefault();
                          fileInputRef.current.click();
                        }}
                      ></Icon>
                      <span className="fw-semibold text-secondary-light">
                        Upload
                      </span>
                    </label>
                  )}

                  {/* Always render the input, but hide it */}
                  <input
                    id="upload-file"
                    type="file"
                    onChange={handleFileChange}
                    hidden
                    ref={fileInputRef}
                    accept="image/*" // Optional: restrict to image files
                  />
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  onClick={
                                
                    (e) =>  {setActive(!status); console.log(!status)}

                    
                  }
                  checked={status}
                />
              </div>
              <label
                htmlFor="sandbox"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
                Active
              </label>
            </div>

            <div className="d-flex align-items-center justify-content-center gap-3 mt-24">
            
              <button
                type="submit"
                className="btn btn-primary-600 border border-primary-600 text-md px-24 py-12 radius-8"
              >
                Save Change
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditRestaurantCategoryLayer;
