import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";
import EditPhaseLayer from "../../components/locations/EditPhaseLayer";


const EditPhasePage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Edit Phase" />

        {/* AddUserLayer */}
        <EditPhaseLayer />


      </MasterLayout>
    </>
  );
};

export default EditPhasePage;
