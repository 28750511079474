import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";
import AddAreaLayer from "../../components/locations/AddAreaLayer";


const AddAreaPage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Add Area" />

        {/* AddUserLayer */}
        <AddAreaLayer />


      </MasterLayout>
    </>
  );
};

export default AddAreaPage;
