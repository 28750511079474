import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { API_URL } from "../../constants/api";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const EditBankLayer = () => {
 
  const {id} = useParams()
  const token = localStorage.getItem("pfp-token");
  const navigate = useNavigate();
  const [bank_name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [image, setImage] = useState(null)
  const [banner, setBanner] = useState(null)
  const [status, setActive] = useState()


// bank image handling

const [imagePreview, setImagePreview] = useState(null);
const fileInputRef = useRef(null);

const handleFileChange = (e) => {
 
    if (e.target.files.length) {
        const src = URL.createObjectURL(e.target.files[0]);
        setImagePreview(src);
        setImage(e.target.files[0])
    }
};

const removeImage = () => {
    setImagePreview(null);
    if (fileInputRef.current) {
        fileInputRef.current.value = "";
        setImage(null)
    }
};

useEffect(() => {
    return () => {
        if (imagePreview) {
            URL.revokeObjectURL(imagePreview);
        }
    };
}, [imagePreview]);
// banner image handling

const [bannerPreview, setBannerPreview] = useState(null);
const bannerRef= useRef(null);

const handleFileChangeBanner = (e) => {
  e.preventDefault()

    if (e.target.files.length) {
        const src = URL.createObjectURL(e.target.files[0]);
        setBannerPreview(src);
        setBanner(e.target.files[0])
    }
};

const removeImage2 = () => {
    setBannerPreview(null);
    if (bannerRef.current) {
        bannerRef.current.value = "";
        setBanner(null)
    }
};

useEffect(() => {
    return () => {
        if (bannerPreview) {
            URL.revokeObjectURL(bannerPreview);
        }
    };
}, [bannerPreview]);

  const handleSubmit = async e => {
    e.preventDefault()

    let active = 0
    if (status === true) {
      active = 1
    } else if (status === false) {
      active = 0
    }

    const formData = new FormData()

  
      formData.append("bank_name", bank_name)
      formData.append("email", email)
      formData.append("active", active)

      if(image){
        formData.append("image", image)
      }
      if(banner){
        formData.append("banner_image", banner)
      }
    
    
      try {
        const loading = toast.loading("Updating bank")
        const response = await fetch(
          `${API_URL}/api/backend/banks/update/${id}`,
          {
            method: "POST",
            body: formData,
            headers: {
              "Authorization": `Bearer ${token}`,
            }
          },
        )

        const json = await response.json()

        if (response.ok) {
          toast.dismiss(loading)
          toast.success("Bank updated successfully")
          navigate("/all-banks")
  
        }else{
          toast.dismiss(loading)
          toast.error("Error updating bank")
        }
      } catch (error) {
      } finally {
      }

  }

  
  useEffect(() => {
    const fetchBank = async () => {
      try {
        const response = await fetch(
          `${API_URL}/api/backend/banks/${id}`,{
            headers: {
              "Authorization": `Bearer ${token}`,
            }
          }
        )
        const json = await response.json()
        if (response.ok) {
          setName(json.data.bank_name || "")
          setEmail(json.data.email || "")
          setActive(json.data.active === 1 ? true : false)
          setImagePreview(json.data.local_image_small)
          setBannerPreview(json.data.local_banner_image_small)
        } else {
          console.error("Failed to fetch category data:", json.message)
        }
      } catch (error) {
        console.error("Error fetching category data:", error)
      }
    }
    fetchBank()
  }, [id])
  return (
    <div className="card h-100 p-0 radius-12 overflow-hidden">
      <div className="card-body p-40">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-6">
              <div className="mb-20">
                <label
                  htmlFor="name"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Name <span className="text-danger-600">*</span>
                </label>
                <input
                  type="text"
                  className="form-control radius-8"
                  id="name"
                  placeholder="Enter Bank Name"
                  onChange={(e)=>setName(e.target.value)}
                  required
                  value={bank_name}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-20">
                <label
                  htmlFor="email"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Email
                </label>
                <input
                  type="email"
                  className="form-control radius-8"
                  id="email"
                  placeholder="Enter email address"
                  onChange={(e)=>setEmail(e.target.value)}
                  value={email}
                />
              </div>
            </div>
 

            <div className="col-sm-6">
              <div className="mb-20">
                <label
                  htmlFor="email"
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Bank Image 
                </label>
                <div className="upload-image-wrapper d-flex align-items-center gap-3">
                        {/* Image preview section */}
                        {imagePreview ? (
                            <div className="uploaded-img position-relative h-120-px w-120-px border input-form-light radius-8 overflow-hidden border-dashed bg-neutral-50">
                                <button
                                    type="button"
                                    onClick={removeImage}
                                    className="uploaded-img__remove position-absolute top-0 end-0 z-1 text-2xxl line-height-1 me-8 mt-8 d-flex"
                                    aria-label="Remove uploaded image"
                                >
                                    <Icon
                                        icon="radix-icons:cross-2"
                                        className="text-xl text-danger-600"
                                    ></Icon>
                                </button>
                                <img
                                    id="uploaded-img__preview"
                                    className="w-100 h-100 object-fit-cover"
                                    src={imagePreview}
                                    alt="Preview"
                                />
                            </div>
                        ) : (
                            <label
                                className="upload-file h-120-px w-120-px border input-form-light radius-8 overflow-hidden border-dashed bg-neutral-50 bg-hover-neutral-200 d-flex align-items-center flex-column justify-content-center gap-1"
                                htmlFor="upload-file"
                            >
                                <Icon
                                    icon="solar:camera-outline"
                                    className="text-xl text-secondary-light"
                                    onClick={(e)=>{
                                      e.preventDefault();
                                      fileInputRef.current.click()
                                    }}
                                ></Icon>
                                <span className="fw-semibold text-secondary-light">Upload</span>
                            </label>
                        )}

                        {/* Always render the input, but hide it */}
                        <input
                            id="upload-file"
                            type="file"
                            onChange={handleFileChange}
                            hidden
                            ref={fileInputRef}
                            accept="image/*" // Optional: restrict to image files
                        />
                    </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-20">
                <label
                 
                  className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                  Banner Image 
                </label>
                <div className="upload-image-wrapper d-flex align-items-center gap-3">
                        {/* Image preview section */}
                        {bannerPreview ? (
                            <div className="uploaded-img position-relative h-120-px w-120-px border input-form-light radius-8 overflow-hidden border-dashed bg-neutral-50">
                                <button
                                    type="button"
                                    onClick={removeImage2}
                                    className="uploaded-img__remove position-absolute top-0 end-0 z-1 text-2xxl line-height-1 me-8 mt-8 d-flex"
                                    aria-label="Remove uploaded image"
                                >
                                    <Icon
                                        icon="radix-icons:cross-2"
                                        className="text-xl text-danger-600"
                                    ></Icon>
                                </button>
                                <img
                                    id="uploaded-img__preview"
                                    className="w-100 h-100 object-fit-cover"
                                    src={bannerPreview}
                                    alt="Preview"
                                />
                            </div>
                        ) : (
                            <label
                                className="upload-file h-120-px w-120-px border input-form-light radius-8 overflow-hidden border-dashed bg-neutral-50 bg-hover-neutral-200 d-flex align-items-center flex-column justify-content-center gap-1"
                                htmlFor="upload-banner"
                            >
                                <Icon
                                    icon="solar:camera-outline"
                                    className="text-xl text-secondary-light"
                                    onClick={(e)=>{
                                      e.preventDefault();
                                      bannerRef.current.click()
                                    }}
                                ></Icon>
                                <span className="fw-semibold text-secondary-light">Upload</span>
                            </label>
                        )}

                        {/* Always render the input, but hide it */}
                        <input
                            id="upload-banner"
                            type="file"
                            onChange={handleFileChangeBanner}
                            hidden
                            ref={bannerRef}
                            accept="image/*" // Optional: restrict to image files
                        />
                    </div>
              </div>
            </div>
          
            <div className="d-flex align-items-center gap-10 fw-medium text-lg">
              <div className="form-check style-check d-flex align-items-center">
                <input
                  className="form-check-input radius-4 border border-neutral-500"
                  type="checkbox"
                  name="checkbox"
                  onChange={(e) => setActive(e.target.checked)}
                  checked={status}
                  onClick={e => setActive(!status)}
                />
              </div>
              <label
                htmlFor="sandbox"
                className="form-label fw-medium text-lg text-primary-light mb-0"
              >
                Active
              </label>
            </div>
              
            <div className="d-flex align-items-center justify-content-center gap-3 mt-24">
              {/* <button
                type="reset"
                className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-40 py-11 radius-8"
              >
                Reset
              </button> */}
              <button
                type="submit"
                className="btn btn-primary-600 border border-primary-600 text-md px-24 py-12 radius-8"
              >
                Save Change
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditBankLayer;
