import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";
import AddDishCuisineLayer from "../../components/dishCuisine/AddDishCuisineLayer";


const AddDishCuisinePage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Add Dish Cuisine" />

        {/* AddUserLayer */}
        <AddDishCuisineLayer />


      </MasterLayout>
    </>
  );
};

export default AddDishCuisinePage;
