import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useContext, useEffect, useState } from "react";
import { ModeContext, ProfileContext } from "../App";
import { API_URL } from "../constants/api";
import { format } from "date-fns";
import Select from "react-select";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const ViewProfileLayer = () => {
  const [imagePreview, setImagePreview] = useState(
    "/assets/images/user-grid/user-grid-img13.png"
  );
  const [image, setImage] = useState(null)
  const [namePassword, setNamePassword] = useState("")
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const { profileUpdate, setProfileUpdate } = useContext(ProfileContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState(null);
  const [group_name, setGroupName] = useState("");
  const [mobile_no, setMobileNo] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [admin_user_group_id, setGroupId] = useState();
  const [admin_user_group_id_password, setGroupIdPassword] = useState();
  const [groups, setGroups] = useState([]);
  const GROUP_ID = localStorage.getItem("group_id");
  const { mode, setMode } = useContext(ModeContext);
  const [validationErrors, setValidationErrors] = useState({})
  const [fileChanged, setFileChanged] = useState(false)

  const token = localStorage.getItem("pfp-token");

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(`${API_URL}/api/backend/auth/profile`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const json = await response.json();

        if (response.ok) {
          setName(json.data.name || "");
          setNamePassword(json.data.name || "");
          setEmail(json.data.email || "");
          setMobileNo(json.data.mobile_no);
          setImagePreview(
            json.data.cdn_image_small ||
              "/assets/images/user-grid/user-grid-img13.png"
          );
          setGroupId(json.data.admin_user_group_id);
          setGroupIdPassword(json.data.admin_user_group_id);
          setGroupName(json.data.group_name || "");
          setDate(json.data.created_at);
        } else {
        }
      } catch (error) {
        console.error(error);
      } finally {
        // Clear form fields if necessary
      }
    };
    const fetchGroups = async () => {
      try {
        const response = await fetch(
          `${API_URL}/api/backend/admin/groups/list`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const json = await response.json();

        if (response.ok) {
          let my_group = json.data.map((group) => ({
            label: group.group_name,
            value: group.id,
          }));

          setGroups(my_group);
        } else {
        }
      } catch (error) {
        console.error(error);
      } finally {
        // Clear form fields if necessary
      }
    };

    fetchProfile();
    fetchGroups();
  }, [profileUpdate]);

  // Toggle function for password field
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Toggle function for confirm password field
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const validateForm = () => {
    let errors = {}
    if (!admin_user_group_id) {
      errors.group_id = "Group is required"
    }
    // if (!restaurant_dish_cuisine_id) {
    //   errors.cuisine = "Cuisine is required";
    // }

    setValidationErrors(errors)
    return Object.keys(errors).length === 0 // Returns true if there are no errors
  }

  const handleSubmit = async e => {
    e.preventDefault()

    await validateForm()

    const formData = new FormData()

    formData.append("name", name)
    formData.append("mobile_no", mobile_no)
    formData.append("password", password)
    formData.append("admin_user_group_id", admin_user_group_id)
    if (fileChanged) {
      if (image) {
        formData.append("image", image)
      }
    }

    try {
        const loading = toast.loading("Updating profile...")
      const response = await fetch(
        `${API_URL}/api/backend/auth/update`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      const json = await response.json()

      if (response.ok) {
        toast.dismiss(loading)
        toast.success(json.message)
        // setSuccess(json.message)
    
        // setUpdate(update + 1)
        // setPasswordError("")
        // setFileChanged(false)
        setProfileUpdate(profileUpdate + 1)
      } else {
        toast.dismiss(loading)
        toast.error(json.message)
        // setPasswordError(json.message)
      }
    } catch (error) {
      console.error(error)
    } finally {
      // Clear form fields if necessary
      setImage(null)
    }
  }


  const handlePasswordChange = async e => {
    e.preventDefault()

    if (password !== confirmPassword) {
        toast.error("Passwords are not matching")
        console.log(password.length)
      }else{
        if(password.length > 6){
          

          const data = {
            password
          }
    
    
    
        try {
            const loading = toast.loading("Changing password...")
          const response = await fetch(
            `${API_URL}/api/backend/auth/password/update`,
            {
              method: "PUT",
              body: JSON.stringify(data),
              headers: {
                'Content-Type': "Application/json",
                Authorization: `Bearer ${token}`,
              },
            },
          )
    
          const json = await response.json()
    
          if (response.ok) {
            toast.dismiss(loading)
            toast.success("Password changed")
            setPassword("")
            setConfirmPassword("")
            // setSuccess(json.message)
        
            // setUpdate(update + 1)
            // setPasswordError("")
            // setFileChanged(false)
            setProfileUpdate(profileUpdate + 1)
          } else {
            toast.dismiss(loading)
            toast.error("Error changing password")
            // setPasswordError(json.message)
          }
        } catch (error) {
          console.error(error)
        } finally {
          // Clear form fields if necessary
      
          setImage(null)
        }
        }else{
            toast.error("Passwords should be at least 6 characters")
        }
        
    
   

      }


  }

  const readURL = (input) => {
    if (input.target.files && input.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
        setFileChanged(true)
        setImage(input.target.files[0])
      };
      reader.readAsDataURL(input.target.files[0]);
    }
  };
  const colorStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "#273142",
      borderColor: state.isFocused ? "#blue" : "#ccc",
      boxShadow: state.isFocused ? "0 0 0 1px #blue" : "none",
      "&:hover": {
        borderColor: "#blue",
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? "#24A97B"
        : state.isFocused
        ? "#273142"
        : "#273142",
      color: state.isSelected ? "black" : "white",
      "&:hover": {
        backgroundColor: "#000000",
      },
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "#ffffff",
      border: "1px solid #ccc",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#ffffff",
    }),
    singleValue: (base) => ({
      ...base,
      color: "#ffffff",
    }),
    input: (base) => ({
      ...base,
      color: "#ffffff", // Set the search text color here
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: "#000000", // Background color for selected values
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: "#ffffff", // Text color for selected value labels
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: "#ffffff", // Text color for the remove icon
      "&:hover": {
        backgroundColor: "red", // Background color on hover
        color: "white", // Text color on hover
      },
    }),
  };
  return (
    <div className="row gy-4">
      <div className="col-lg-4">
        <div className="user-grid-card position-relative border radius-16 overflow-hidden bg-base h-100">
          <img
            src="assets/images/auth/profile-cover.png"
            alt=""
            className="w-100 object-fit-cover"
          />
          <div className="pb-24 ms-16 mb-24 me-16  mt--100">
            <div className="text-center border border-top-0 border-start-0 border-end-0">
              <img
                src={imagePreview}
                alt=""
                className="border br-white border-width-2-px w-200-px h-200-px rounded-circle object-fit-cover"
              />
              <h6 className="mb-0 mt-16">{name}</h6>
              <span className="text-secondary-light mb-16">{email}</span>
            </div>
            <div className="mt-24">
              <h6 className="text-xl mb-16">Personal Info</h6>
              <ul>
                <li className="d-flex align-items-center gap-1 mb-12">
                  <span className="w-30 text-md fw-semibold text-primary-light">
                    Full Name
                  </span>
                  <span className="w-70 text-secondary-light fw-medium">
                    {name}
                  </span>
                </li>
                <li className="d-flex align-items-center gap-1 mb-12">
                  <span className="w-30 text-md fw-semibold text-primary-light">
                    {" "}
                    Email
                  </span>
                  <span className="w-70 text-secondary-light fw-medium">
                    {email}
                  </span>
                </li>
                <li className="d-flex align-items-center gap-1 mb-12">
                  <span className="w-30 text-md fw-semibold text-primary-light">
                    {" "}
                    Phone Number
                  </span>
                  <span className="w-70 text-secondary-light fw-medium">
                    {mobile_no ? mobile_no : "N/a"}
                  </span>
                </li>
                <li className="d-flex align-items-center gap-1 mb-12">
                  <span className="w-30 text-md fw-semibold text-primary-light">
                    {" "}
                    Department
                  </span>
                  <span className="w-70 text-secondary-light fw-medium">
                    Admins & Roles
                  </span>
                </li>
                <li className="d-flex align-items-center gap-1 mb-12">
                  <span className="w-30 text-md fw-semibold text-primary-light">
                    {" "}
                    Designation
                  </span>
                  <span className="w-70 text-secondary-light fw-medium">
                    {group_name}
                  </span>
                </li>
                <li className="d-flex align-items-center gap-1 mb-12">
                  <span className="w-30 text-md fw-semibold text-primary-light">
                    {" "}
                    Date Joined
                  </span>
                  <span className="w-70 text-secondary-light fw-medium">
                    {date ? format(date, "dd MMM, yyyy") : ""}
                  </span>
                </li>
                <li className="d-flex align-items-center gap-1">
                  <span className="w-30 text-md fw-semibold text-primary-light">
                    {" "}
                    Bio
                  </span>
                  <span className="w-70 text-secondary-light fw-medium">
                    I'm leading as {group_name} in Pakistan Food Portal a go-to
                    platform for all things food.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-8">
        <div className="card h-100">
          <div className="card-body p-24">
            <ul
              className="nav border-gradient-tab nav-pills mb-20 d-inline-flex"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link d-flex align-items-center px-24 active"
                  id="pills-edit-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-edit-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-edit-profile"
                  aria-selected="true"
                >
                  Edit Profile
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link d-flex align-items-center px-24"
                  id="pills-change-passwork-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-change-passwork"
                  type="button"
                  role="tab"
                  aria-controls="pills-change-passwork"
                  aria-selected="false"
                  tabIndex={-1}
                >
                  Change Password
                </button>
              </li>
              {/* <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link d-flex align-items-center px-24"
                                    id="pills-notification-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-notification"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-notification"
                                    aria-selected="false"
                                    tabIndex={-1}
                                >
                                    Notification Settings
                                </button>
                            </li> */}
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-edit-profile"
                role="tabpanel"
                aria-labelledby="pills-edit-profile-tab"
                tabIndex={0}
              >
                <h6 className="text-md text-primary-light mb-16">
                  Profile Image
                </h6>
                {/* Upload Image Start */}
                <div className="mb-24 mt-16">
                  <div className="avatar-upload">
                    <div className="avatar-edit position-absolute bottom-0 end-0 me-24 mt-16 z-1 cursor-pointer">
                      <input
                        type="file"
                        id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                        hidden
                        onChange={readURL}
                      />
                      <label
                        htmlFor="imageUpload"
                        className="w-32-px h-32-px d-flex justify-content-center align-items-center bg-primary-50 text-primary-600 border border-primary-600 bg-hover-primary-100 text-lg rounded-circle"
                      >
                        <Icon
                          icon="solar:camera-outline"
                          className="icon"
                        ></Icon>
                      </label>
                    </div>
                    <div className="avatar-preview">
                      <div
                        id="imagePreview"
                        style={{
                          backgroundImage: `url(${imagePreview})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* Upload Image End */}
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="mb-20">
                        <label
                          htmlFor="name"
                          className="form-label fw-semibold text-primary-light text-sm mb-8"
                        >
                          Full Name
                          <span className="text-danger-600">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control radius-8"
                          id="name"
                          placeholder="Enter Full Name"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="mb-20">
                        <label
                          htmlFor="email"
                          className="form-label fw-semibold text-primary-light text-sm mb-8"
                        >
                          Email <span className="text-danger-600">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control radius-8"
                          id="email"
                          placeholder="Enter email address"
                          value={email}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="mb-20">
                        <label
                          htmlFor="number"
                          className="form-label fw-semibold text-primary-light text-sm mb-8"
                        >
                          Phone
                        </label>
                        <input
                          type="text"
                          className="form-control radius-8"
                          id="number"
                          placeholder="Enter phone number"
                          onChange={(e) => setMobileNo(e.target.value)}
                          value={mobile_no}
                          required
                          
                        />
                      </div>
                    </div>
                    {
                      GROUP_ID === "3" || GROUP_ID === "2"?
                      <></>
                      :
                      <div className="col-sm-12">
                      <div className="mb-20">
                        <label
                          htmlFor="depart"
                          className="form-label fw-semibold text-primary-light text-sm mb-8"
                        >
                          Role
                          <span className="text-danger-600">*</span>{" "}
                        </label>
                        <Select
                          onChange={(e) => setGroupId(e.value)}
                          options={groups}
                          value={groups.find(
                            (group) => group.value === admin_user_group_id
                          )}
                          styles={mode !== "light" ? colorStyles : undefined}
                          classNamePrefix="select2-selection"
                          validate={{ required: { value: true } }}
                          
                        />
                      </div>
                    </div>
                    }
            
          
                  </div>
                  <div className="d-flex align-items-center justify-content-center gap-3">
                    <Link
                      to="/"
                      className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-56 py-11 radius-8"
                    >
                      Cancel
                    </Link>
                    <button
                      type="submit"
                      className="btn btn-primary-600 border border-primary-600 text-md px-56 py-12 radius-8"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
              <form onSubmit={handlePasswordChange}>
              <div
                className="tab-pane fade"
                id="pills-change-passwork"
                role="tabpanel"
                aria-labelledby="pills-change-passwork-tab"
                tabIndex="0"
              >
                <div className="mb-20">
                  <label
                    htmlFor="your-password"
                    className="form-label fw-semibold text-primary-light text-sm mb-8"
                  >
                    New Password <span className="text-danger-600">*</span>
                  </label>
                  <div className="position-relative">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      className="form-control radius-8"
                      id="your-password"
                      placeholder="Must be at least 6 characters*"
                      onChange={(e)=>setPassword(e.target.value)}
                      value={password}
                    />
                    <span
                      className={`toggle-password ${
                        passwordVisible ? "ri-eye-off-line" : "ri-eye-line"
                      } cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light`}
                      onClick={togglePasswordVisibility}
                    ></span>
                  </div>
                </div>

                <div className="mb-20">
                  <label
                    htmlFor="confirm-password"
                    className="form-label fw-semibold text-primary-light text-sm mb-8"
                  >
                    Confirm Password <span className="text-danger-600">*</span>
                  </label>
                  <div className="position-relative">
                    <input
                      type={confirmPasswordVisible ? "text" : "password"}
                      className="form-control radius-8"
                      id="confirm-password"
                      placeholder="Confirm Password*"
                      onChange={(e)=>setConfirmPassword(e.target.value)}
                      value={confirmPassword}
                    />
                    <span
                      className={`toggle-password ${
                        confirmPasswordVisible
                          ? "ri-eye-off-line"
                          : "ri-eye-line"
                      } cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light`}
                      onClick={toggleConfirmPasswordVisibility}
                    ></span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center gap-3">
                    <button
                      type="submit"
                      className="btn btn-primary-600 border border-primary-600 text-md px-56 py-12 radius-8"
                    >
                      Change Password
                    </button>
                  </div>
              </div>
              </form>
           
              <div
                className="tab-pane fade"
                id="pills-notification"
                role="tabpanel"
                aria-labelledby="pills-notification-tab"
                tabIndex={0}
              >
                <div className="form-switch switch-primary py-12 px-16 border radius-8 position-relative mb-16">
                  <label
                    htmlFor="companzNew"
                    className="position-absolute w-100 h-100 start-0 top-0"
                  />
                  <div className="d-flex align-items-center gap-3 justify-content-between">
                    <span className="form-check-label line-height-1 fw-medium text-secondary-light">
                      Company News
                    </span>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="companzNew"
                    />
                  </div>
                </div>
                <div className="form-switch switch-primary py-12 px-16 border radius-8 position-relative mb-16">
                  <label
                    htmlFor="pushNotifcation"
                    className="position-absolute w-100 h-100 start-0 top-0"
                  />
                  <div className="d-flex align-items-center gap-3 justify-content-between">
                    <span className="form-check-label line-height-1 fw-medium text-secondary-light">
                      Push Notification
                    </span>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="pushNotifcation"
                      defaultChecked=""
                    />
                  </div>
                </div>
                <div className="form-switch switch-primary py-12 px-16 border radius-8 position-relative mb-16">
                  <label
                    htmlFor="weeklyLetters"
                    className="position-absolute w-100 h-100 start-0 top-0"
                  />
                  <div className="d-flex align-items-center gap-3 justify-content-between">
                    <span className="form-check-label line-height-1 fw-medium text-secondary-light">
                      Weekly News Letters
                    </span>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="weeklyLetters"
                      defaultChecked=""
                    />
                  </div>
                </div>
                <div className="form-switch switch-primary py-12 px-16 border radius-8 position-relative mb-16">
                  <label
                    htmlFor="meetUp"
                    className="position-absolute w-100 h-100 start-0 top-0"
                  />
                  <div className="d-flex align-items-center gap-3 justify-content-between">
                    <span className="form-check-label line-height-1 fw-medium text-secondary-light">
                      Meetups Near you
                    </span>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="meetUp"
                    />
                  </div>
                </div>
                <div className="form-switch switch-primary py-12 px-16 border radius-8 position-relative mb-16">
                  <label
                    htmlFor="orderNotification"
                    className="position-absolute w-100 h-100 start-0 top-0"
                  />
                  <div className="d-flex align-items-center gap-3 justify-content-between">
                    <span className="form-check-label line-height-1 fw-medium text-secondary-light">
                      Orders Notifications
                    </span>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="orderNotification"
                      defaultChecked=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProfileLayer;
