import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";
import AddMenuLayer from "../../components/restaurants/AddMenuLayer";


const AddMenuPage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Add Menu" />

        {/* AddUserLayer */}
        <AddMenuLayer />


      </MasterLayout>
    </>
  );
};

export default AddMenuPage;
