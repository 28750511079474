import React from "react";
import MasterLayout from "../../masterLayout/MasterLayout";
import Breadcrumb from "../../components/Breadcrumb";
import AddBankLayer from "../../components/banks/AddBankLayer";


const AddBankPage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="Add Bank" />

        {/* AddUserLayer */}
        <AddBankLayer />


      </MasterLayout>
    </>
  );
};

export default AddBankPage;
